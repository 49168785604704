<template>
    <div>
        <div class="title-wrapper">
            <h1>Báo cáo ({{ total }})</h1>
            <div>
                <button type="button" class="btn btn-danger" @click="removeSelectedItemsAsync()" 
                    :disabled="isInDeletingProcess || !isRemoveButton">
                    <div v-show="isInDeletingProcess" class="spinner-border text-light spinner-border-sm" role="status" style="display: inline-block; margin-right: 5px;">
                        <span class="sr-only">Loading...</span>
                    </div>
                    Xóa vĩnh viễn</button>
            </div>
        </div>
        
        <!-- Filter -->
        <form method="get" @submit.prevent="filterFromAsync">
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-3">
                        <input v-model="filter.from_project_name" type="text" placeholder="Tìm theo tên dự án..." class="form-control">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <select v-model="filter.order_by" class="form-control" @change="filterFromAsync">
                            <option value="updated_at">Mới nhất</option>
                            <option value="duplicate_count">Xu hướng</option>
                        </select>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại bộ lọc</button>
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <!-- Data table -->
        <div class="data-table">
            <table>
                <colgroup>
                    <col width="5%">
                    <col width="30%">
                    <col width="20%">
                </colgroup>
                <thead>
                    <tr>
                        <th><input id="checkAll" type="checkbox" style="margin-left: 5px;" @click="toogleSelectAll($event)"></th>
                        <th>Data</th>
                        <th>Nguồn</th>
                        <th>Điểm xu hướng</th>
                        <th>Ngày cập nhật cuối cùng</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="5">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td>
                            <input v-model="item.selected" type="checkbox">
                        </td>
                        <td><span style="color: #0080c0; cursor: pointer;" @click="item.selected = !item.selected">{{ item.data }}</span></td>
                        <td>
                            <b>{{ item.from_project_name }}</b>
                            <br>
                            <small>Khách hàng : {{ item.from_customer_name ? item.from_customer_name :'Unknow'  }}</small>
                        </td>
                        <td>{{ item.duplicate_count }}</td>
                        <td>{{ formatDateTime(item.updated_at) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/dataReportService';
import { calcPaginationPageList, calcPageAfterDelete } from '@/helpers/paginationHelper';
import { formatDateTimeString } from '@/helpers/strHelper';
import NProgress from 'nprogress';

export default {
    name: 'DataReportPage',
    components: {
    },
    created() {
        document.title = 'Báo cáo';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.per_page) this.filter.per_page = 20;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.order_by) this.filter.order_by = 'duplicate_count';
        if (!this.filter.from_project_name) this.filter.from_project_name = '';
        if (!this.filter.from_customer_name) this.filter.from_customer_name = '';
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
    },
    computed: {
        selected() {
            return this.data.map(item => item.selected)
        }
    },
    watch: {
        selected() {
            this.isRemoveButton = this.data.filter(item => item.selected).length > 0;
        }
    },
    data() {
        return {
            data: [],
            filter: {
                page: 1,
                per_page: 20,
                order_by: 'duplicate_count',
                from_project_name: '',
                from_customer_name: ''
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            isRemoveButton: false,
            isInDeletingProcess: false,
            inLoading: true
        }
    },
    methods: {
        async removeSelectedItemsAsync() {
            if (confirm(`Bạn có chắc chắn muốn xóa vĩnh viễn dữ liệu đang chọn?`)) {
                this.isInDeletingProcess = true;
                NProgress.start();
                const selectedCount = this.data.filter(item => item.selected).length;
                
                let ids = [];
                this.data.filter(item => item.selected).forEach(async (item) => {
                    ids.push(item.id);
                });

                const result = await db.bulkRemoveAsync(ids);

                if (result.success) {
                    // Reload after
                    // Note: we must re-cacluate the page
                    this.filter.page = calcPageAfterDelete(this.total, selectedCount, this.filter.page, this.filter.per_page);
                    this.$toast.success('Xóa thành công dữ liệu đã chọn');
                    await this.loadAsync();
                } else {
                    this.$toast.error(result.message);
                }
                
                document.getElementById('checkAll').checked = false;
                NProgress.done();
                this.isInDeletingProcess = false;
            }
        },

        async filterFromAsync(){
            this.filter.page = 1;
            await this.loadAsync();
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });
                
            const result = await db.getAsync(this.filter.page, this.filter.per_page, this.filter);

            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },

        toogleSelectAll(event) {
            const selected = event.target.checked;
            this.data.forEach(item => item.selected = selected);
        },

        // Filter ----------------------------------------------------------------------------------
        async resetFilterAsync(){
            this.filter.page = 1;
            this.filter.from_project_name = '';
            this.filter.from_customer_name = '';
            this.filter.order_by = 'duplicate_count';

            await this.loadAsync();
        },
        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // UI event ------------------------------------------------------------------------------------
        formatDateTime(datetimeStr){
            return formatDateTimeString(datetimeStr);
        }
    }
    
}
</script>

<style>
b {
    color: #525252;
}
</style>

/**
 *  Get a item index in an array
 * 
 * @param {Array} data 
 * @param {String} property 
 * @param {*} val 
 * @returns {*} { 'item': obj, 'index': index };
 */
const getItemIndex = (data, property, val) => {
    const obj = data.find(item => item[property] == val);

    let index = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i][property] == val) {
            index = i;
            break;
        }
    }

    return { 'item': obj, 'index': index };
}


export {getItemIndex};
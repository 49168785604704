<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Sửa nguồn doanh thu</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <h5>1. Thông tin chung</h5>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="price">Số tiền <span class="cl-red">*</span> <small>x1000 vnd</small></label>
                            <input v-model="revenueObj.price" type="number" class="form-control" id="price">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="customer">Khách hàng <span class="cl-red">*</span></label>
                            <UserSearchBox v-model="revenueObj.customer_id" ref="selectCustomer" id="customer"></UserSearchBox>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="project">Dự án<span class="cl-red">*</span></label>
                            <SelectBox v-model="revenueObj.project_id" ref="selectProject" placeHolder="-- Chọn dự án" id="project"></SelectBox>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="content">Nội dung</label>
                            <input v-model="revenueObj.content" type="text" class="form-control" id="content">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="created_at">Ngày</label>
                            <input v-model="revenueObj.created_at" type="date" class="form-control" id="created_at">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="note">Ghi chú</label>
                            <input v-model="revenueObj.note" type="text" class="form-control" id="note">
                        </div>
                    </div>
                </div>

                <div style="display: flex;">
                    <input v-model="revenueObj.is_private" type="checkbox" id="is_private" style="margin-right: 6px">
                    <label for="is_private">Riêng tư</label>
                </div>
                <br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Cập nhật</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import { updateAsync as editRevenueAsync, findAsync as findRevenueAsync} from '@/services/revenueService';
import { getAsync as getProjectsAsync} from '@/services/projectService';

export default {
    name: "EditRevenuePage",
    components: {
        UserSearchBox,
        SelectBox,
    },
    data(){
        return {
            revenueObj: {
                id: null,
                project_id: null,
                customer_id: null,
                price: null,
                content: null,
                note : null,
                is_private: false,
                created_at: ''
            },
            uiData: {
                projects: []
            },
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Sửa nguồn doanh thu';
    },
    async mounted(){
        this.revenueObj.id = this.$route.params['id'];
        await this.loadProjectSelectBoxAsync();
        await this.loadRevenueAsync();
    },
    methods:{
        async submitAsync() {
            this.onSubmiting = true;
            const result = await editRevenueAsync(this.revenueObj.id, this.revenueObj);

            if (result.success == true) {
                this.$toast.success('Cập nhật thành công');
                this.$router.push({name: 'revenue'});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Data loader -------------------------------------------------------------------------------
        async loadRevenueAsync(){
            const result = await findRevenueAsync(this.revenueObj.id);
            
            if (result.success == true){
                this.revenueObj = result.data;
                const currentDate = new Date(result.data.created_at);
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth()+1;
                const currentDay = currentDate.getDate();

                this.revenueObj.created_at = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${currentDay.toString().padStart(2, '0')}`;
                this.revenueObj.is_private = (result.data.is_private == 1);
                this.$refs.selectProject.setSelectedId(this.revenueObj.project_id);
                this.$refs.selectCustomer.setSelectedId(this.revenueObj.customer_id, this.revenueObj.customer.display_name + ' - '+this.revenueObj.customer.email);
            }
        },
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.uiData.projects = result.data.data;
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');
            }
        }
    }
}
</script>

<style scoped>
.modules-container {
    display: flex;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}

.modules-container__item {
    padding: 5px 15px 5px 0px;
}

.modules-container__item input {
    margin-right: 6px;
}
</style>
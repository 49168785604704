<template>
    <div class="login-container">
        <form @submit.prevent="submitLoginAsync">
            <div class="login-container__logo">
                <img src="../../assets/login-icon.png">
            </div>
            <h1>Đăng nhập hệ thống</h1>
            <div class="error" v-if="errorMsg">
                <span>{{ errorMsg }}</span>
            </div>
            <br> 
            
            <AwesomeInput id="email" type="email" label="Email" v-model="email"></AwesomeInput>
            <AwesomeInput id="password" type="password" label="Mật khẩu" v-model="password"></AwesomeInput>
            
            <br>
            <button type="submit" class="btn btn-blue form-control" :class="{'loading-btn': onSubmiting}">
                <div class="spinner-border text-light spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span>Đăng nhập</span>
            </button>
            <br/>
            <p>
                <small>Nhập quá 5 lần sai mật khẩu, tài khoản của bạn sẽ bị khóa. Nếu quên mật khẩu, hãy truy cập trang user để sử dụng chức năng lấy lại mật khẩu qua email !</small>
            </p>
        </form>

    </div>
</template>

<script>
import AwesomeInput from '@/components/AwesomeInput.vue';
import { loginAsync } from '@/services/authService';

export default {
    name: 'LoginPage',
    components: {
        AwesomeInput
    },
    data(){
        return {
            title: 'Login to Admin site',
            email: '',
            password: '',
            loginToken: '',
            onSubmiting: false,
            errorMsg: ''
        }
    },
    created() {
        document.title = this.title;
    },
    methods: {
         // Login
        async submitLoginAsync(){
            this.onSubmiting = true;

            // Validate
            if (this.loginToken.length == 0) {
                if (this.email.length == 0) {
                    this.onSubmiting = false;
                    this.errorMsg = 'Vui lòng nhập email';
                    return;
                }
                else if (this.password.length < 6) {
                    this.onSubmiting = false;
                    this.errorMsg = 'Mật khẩu phải có từ 6 kí tự';
                    return;
                } 
            }  

            // Login
            const loginResult = await loginAsync(this.email, this.password, this.loginToken);
            
            if (loginResult.success == true) {
                this.$router.push({name: 'home'});
            }
            else {
                this.onSubmiting = false;
                this.errorMsg = loginResult.message;
            }
        }
    }
   
};
</script>

<style scoped>
h1 {
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
}

input, button {
    margin-bottom: 15px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    opacity: 0.7;
}

.login-container__logo {
    width: 80px;
    margin: 10px auto;
}
.login-container__logo img {
    max-width: 100%;
}

.error span {
    color: red;
    text-align: center;
    display: block;
}
</style>

<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Thêm license dùng thử</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <div class="form-group">
                    <label for="customer">Khách hàng Test User<span class="cl-red">*</span></label>
                    <UserSearchBox v-model="backendData.customer_id" placeHolder="-- Chọn tới Test user" id="customer"></UserSearchBox>
                </div>
                <div class="form-group">
                    <label for="project">Dự án <span class="cl-red">*</span></label>
                    <SelectBox v-model="backendData.project_id" ref="selectProject" placeHolder="-- Chọn dự án" id="project"></SelectBox>
                </div>
                <div class="form-group">
                    <label for="trial_days">Số ngày dùng thử <span class="cl-red">*</span></label>
                    <select v-model="backendData.trial_days" class="form-control" id="trial_days">
                        <option value="3">3</option>
                        <option value="7" selected>7</option>
                        <option value="30">30</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="count">Số license dùng thử <span class="cl-red">*</span></label>
                    <input v-model="backendData.count" type="number" class="form-control" id="count">
                </div>
                <br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo mới</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import {trialCreateAsync} from '@/services/licenseService';
import {getAsync as getProjectsAsync} from '@/services/projectService';

export default {
    name: "CreateTrialLicensePage",
    components: {
        UserSearchBox,
        SelectBox
    },
    data(){
        return {
            backendData: {
                customer_id: null,
                project_id: null,
                trial_days: 7,
                count: 100
            },
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Thêm license dùng thử';
    },
    async mounted(){
        await this.loadProjectSelectBoxAsync();
    },
    methods:{
        async submitAsync() {
            this.onSubmiting = true;

            // Insert to db
            const result = await trialCreateAsync(this.backendData);

            if (result.success == true) {
                this.$toast.success(`Licenses dùng thử đã được tạo thành công`);
                this.$router.push({name: 'licenses'});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Data loader -------------------------------------------------------------------------------
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');
            }
        }
    }
}
</script>

<style scoped>
.project-container {
    max-height:400px;
    height: 400px;
    overflow:auto; 
    border: 1px solid #D8D8D8; 
    border-radius: 5px;
    padding: 15px;
}

.project-container input {
    margin-right: 5px;
}
</style>
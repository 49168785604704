<template>
    <div>
        <div class="title-wrapper">
            <h1>Tổng quan</h1>
        </div>

        <!-- Report card -->
        <div class="row">
            <div class="col-md-15">
                <h5>Tổng số</h5>
                <br>
            </div>
            <div class="col-md-3">
                <div class="reportCard">
                    <h5>Licenses</h5>
                    <span class="data">{{ dashboardData.licenses.total }}</span>
                    <span class="comparePercent" :class="{'data-up' : dashboardData.licenses.is_increase, 'data-down': !dashboardData.licenses.is_increase}">
                        {{ dashboardData.licenses.compare_percent }}%
                        <i v-if="!dashboardData.licenses.is_increase" class="fas fa-long-arrow-alt-down"></i>
                        <i v-else class="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span class="icon"><i class="fab fa-codepen"></i></span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="reportCard">
                    <h5>Doanh thu</h5>
                    <span class="data">{{ formatVNPrice(this.dashboardData.revenue.total) }}</span>
                    <span class="comparePercent" :class="{'data-up' : dashboardData.revenue.is_increase, 'data-down': !dashboardData.revenue.is_increase}">
                        {{ dashboardData.revenue.compare_percent }}%
                        <i v-if="!dashboardData.revenue.is_increase" class="fas fa-long-arrow-alt-down"></i>
                        <i v-else class="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span class="icon"><i class="fas fa-dollar-sign"></i></span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="reportCard">
                    <h5>Người dùng</h5>
                    <span class="data">{{ this.dashboardData.users.total }}</span>
                    <span class="comparePercent" :class="{'data-up' : dashboardData.users.is_increase, 'data-down': !dashboardData.users.is_increase}">
                        {{ dashboardData.users.compare_percent }}%
                        <i v-if="!dashboardData.users.is_increase" class="fas fa-long-arrow-alt-down"></i>
                        <i v-else class="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span class="icon"><i class="fas fa-users"></i></span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="reportCard">
                    <h5>Thiết bị active</h5>
                    <span class="data">{{ this.dashboardData.active_devices.total }}</span>
                    <span class="comparePercent" :class="{'data-up' : dashboardData.active_devices.is_increase, 'data-down': !dashboardData.active_devices.is_increase}">
                        {{ dashboardData.active_devices.compare_percent }}%
                        <i v-if="!dashboardData.active_devices.is_increase" class="fas fa-long-arrow-alt-down"></i>
                        <i v-else class="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span class="icon"><i class="fas fa-laptop"></i></span>
                </div>
            </div>
        </div>

        <!-- Yearly sale -->
        <div class="yearly-sale">
            <div class="row">
                <div class="col-md-8">
                    <h5>Bán hàng trong năm</h5>
                    <br>
                    <select v-model="saleYear" class="form-control" @change="filterSaleYearAsync" style="max-width: 200px;">
                        <option :value="year" v-for="year in yearsData" v-bind:key="year">{{ year }}</option>
                    </select>
                    <br><br>
                    <Bar :options="chartOptions" :data="chartData" v-if="chartData"/>
                </div>
                <div class="col-md-4">
                    <div class="l15">
                        <h5>Top dự án</h5>
                        <br><br>
                        <div v-for="item in dashboardData.top_projects" v-bind:key="item.id" class="projectCard">
                            <div class="projectCard__name">{{ item.name }}</div>
                            <div class="projectCard__licenses">{{ item.licenses_count }}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

import { formatPrice } from '@/helpers/strHelper';
import {getAsync} from '@/services/dashboardService';

export default {
    name: 'DashboardPage',
    components: { Bar },
    created() {
        document.title = 'Dashboard';
    },
    async mounted() {
        const currentYear = (new Date()).getFullYear();
        for (let i = currentYear-5; i <= currentYear+5; i++)
            this.yearsData.push(i);
        
        this.saleYear = currentYear;
        await this.loadSync();
    },
    data() {
        return {
            chartData: null,
            chartOptions: {
                responsive: true
            },
            dashboardData: {
                licenses: {
                    total: 0,
                    compare_percent: 0,
                    is_increase: true
                },
                revenue: {
                    total: 0,
                    compare_percent: 0,
                    is_increase: true
                },
                users: {
                    total: 0,
                    compare_percent: 0,
                    is_increase: true
                },
                active_devices: {
                    total: 0,
                    compare_percent: 0,
                    is_increase: true
                },
                top_projects: {},
                sale_data: {}
            },
            yearsData: [],
            saleYear: null,
        }
    },
    methods: {
        async loadSync() {
            const result = await getAsync(this.saleYear);

            if (result.success == true) {
                this.dashboardData = result.data;

                this.chartData = {
                    labels: [ 'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12' ],
                    datasets: 
                    [ 
                        { 
                            data: this.dashboardData.sale_data,
                            backgroundColor: '#5eaeff',
                            label: 'Tổng số licenses'
                        }
                    ],
                }
            }
        },
        async filterSaleYearAsync() {
            await this.loadSync();
        },

        formatVNPrice(price){
            return formatPrice(price * 1000);
        }
    }
}
</script>

<style scoped>
.reportCard {
    background: white;
    border-radius: 5px;
    border: 1px solid #ececec;
    padding: 15px;
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
}

.reportCard .icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: 7px 5px 5px 5px;
    border: 1px solid #40a0ff;
    background: #b6daff;
    border-radius: 5px;
}

.reportCard .icon i {
    font-size: 18px;
    color: #40a0ff;
}

.reportCard h5 {
    color: #868686;
    margin-bottom: 20px;
}

.reportCard .data {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
}

.reportCard span {
    display: block;
}

.data-down {
    color: red;
}

.data-up {
    color: #00b900;
}

.yearly-sale {
    margin-top: 50px;
}

.projectCard {
    width: 100%;
    padding: 15px;
    background: white;
    border-radius: 5px;
    border: 1px solid #ececec;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
</style>

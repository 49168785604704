<template>
    <div>
        <div class="title-wrapper">
            <h1>Đơn hàng ({{ total }})</h1>
        </div>
        
        <!-- Filter -->
        <form>
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-2">
                        <input v-model="filter.transfer_content" v-on:keyup.enter="searchAsync" type="text" placeholder="Tìm kiếm..." class="form-control">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <UserSearchBox ref="selectCustomer" @onchange="updateCustomerIdFilterAsync"></UserSearchBox>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <select v-model="filter.status" class="form-control" @change="filterByStatusAsync">
                            <option value="all">-- Trạng thái</option>
                            <option value="1">Đã thanh toán</option>
                            <option value="0">Chưa thanh toán</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại bộ lọc</button>
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <!-- Data table -->
        <table class="data-table">
            <colgroup>
                <col width="25%">
                <col width="25%">
                <col width="25%">
            </colgroup>
            <thead>
                <tr>
                    <th>Nội dung chuyển khoản</th>
                    <th>Khách hàng</th>
                    <th>Thông tin</th>
                    <th>Trạng thái</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="loading-row" v-show="inLoading">
                    <td colspan="5">
                        <div class="loading-spinner"></div>
                    </td>  
                </tr>
                <tr class="no-data-row" v-if="data.length == 0 && !inLoading">
                    <td colspan="6">Không có dữ liệu để hiển thị</td>
                </tr>
                <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                    <td>
                        <b>{{ item.transfer_content }}</b><br>
                        <small>Ngày tạo: {{ formatDateTimeStr(item.created_at) }}</small><br>
                        <small>Người tạo: {{ item.created_user.display_name }}</small>
                    </td>
                    <td>
                        <b>{{ item.customer.display_name }}</b><br>
                        <small>{{ item.customer.email }}</small>
                    </td>
                    <td>
                        <b>{{ item.project.name }}</b><br>
                        <small v-if="item.project_package">{{ item.project_package.name }}</small><br>
                        <small>{{ formatVNPrice(item.actual_price) }}</small>
                    </td>
                    <td>
                        <span v-if="item.is_transfered" class="badge bg-success">Đã thanh toán</span>
                        <span v-else class="badge bg-danger">Chữa thanh toán</span>
                    </td>
                    <td>
                        <button @click="setTransferedStatusAsync(item)" class="btn-edit" :disabled="item.is_transfered==1" title="Đặt trạng thái đã thanh toán">
                            <i class="fas fa-check-circle"></i>
                        </button>
                        <a @click="removeAsync(item.id)" class="btn-del" title="Delete"><i class="fas fa-trash-alt"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/orderService';
import { getItemIndex } from '@/helpers/arrayHelper';
import { formatDateString, formatDateTimeString } from '@/helpers/strHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';
import { formatPrice } from '@/helpers/strHelper';

import NProgress from 'nprogress';
import UserSearchBox from '@/components/UserSearchBox.vue';

export default {
    name: 'OrderPage',
    components: {
        UserSearchBox
    },
    created() {
        document.title = 'Đơn hàng';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 15;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.status) this.filter.status = 'all';
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
    },
    data() {
        return {
            data: [],
            filter: {
                page: 1,
                perPage: 15,
                transfer_content: '',
                customer_id: null,
                status: 'all' // all , 0, 1
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có chắc chắn muốn xóa đơn hàng: ' + item.item.transfer_content)) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.total--;
                    this.$toast.success('Xóa đơn hàng thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async searchAsync(){
            this.filter.page = 1;
            this.filter.customer_id = '';

            await this.loadAsync();
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });

            let type = this.filter.status;
            if (type == 'all') type = '';

            const result = await db.getAsync(this.filter.page, this.filter.perPage, this.filter.transfer_content, type, this.filter.customer_id);

            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },
        
        async setTransferedStatusAsync(rowItem) {
            const result = await db.updateTransferedStatusAsync(rowItem.id);

            if (result.success == true)
            {
                rowItem.is_transfered = 1;
                this.$toast.success('Đã chuyển đơn hàng sang trạng thái đã thanh toán');
            }
        },
        
        // Filter ----------------------------------------------------------------------------------
        async resetFilterAsync(){
            this.filter.transfer_content = '';
            this.filter.status = 'all';
            this.filter.customer_id = undefined;
            this.$refs.selectCustomer.setSelectedId(this.filter.customer_id, '');
            this.filter.page = 1;
  
            await this.loadAsync();
        },

        async updateCustomerIdFilterAsync(id){
            this.filter.customer_id = id;
            this.filter.page = 1;

            await this.loadAsync();
        },

        async filterByStatusAsync(){
            this.filter.page = 1;
            this.filter.transfer_content = '';

            await this.loadAsync();
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // Helpers -------------------------------------------------------------------------------------
        formatDateStr(dateString){
            return formatDateString(dateString);
        },
        formatDateTimeStr(dateTimeString){
            return formatDateTimeString(dateTimeString);
        },
        formatVNPrice(price){
            return formatPrice(parseInt(price)*1000);
        }
    }
    
}
</script>
  
<style scoped>
.btn {
    margin-left: 10px;
}

.device-option {
    cursor: pointer;
    margin: 0px 0px 0px 5px;
}

.device-option i {
    margin-right: 5px;
}
</style>
<template>
    <div class="login-layout">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "AuthLayout",
    created(){
        const body = document.getElementsByTagName('body');
        body[0].classList.add('bg-body');
    }
};
</script>
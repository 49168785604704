<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Xử lý lệnh rút tiền</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                
                <div class="row">
                    <div class="col-md-8">
                        <h5>Chi tiết lệnh rút</h5><br>
                        <table>
                            <tr>
                                <td class="td-title">Tài khoản rút</td>
                                <td><b>{{ affiliateObj.withdraw_user.email }}</b></td>
                            </tr>
                            <tr>
                                <td class="td-title">Điểm rút</td>
                                <td><b>{{ affiliateObj.withdraw_point }}</b></td>
                            </tr>
                            <tr>
                                <td class="td-title">Số tài khoản</td>
                                <td><b>{{ affiliateObj.withdraw_user.payment_bank_id }}</b></td>
                            </tr>
                            <tr>
                                <td class="td-title">Ngân hàng</td>
                                <td><b>{{ affiliateObj.withdraw_user.payment_bank_name }} - {{
            affiliateObj.withdraw_user.payment_bank_user_name }}</b></td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <img :src="qrImg" alt="QRImg" style="max-width: 500px; width: 100%;">
                    </div>
                </div>

                <br>
                <h5>Thông tin xử lý</h5><br>
                <div class="form-group">
                    <label for="status">Trạng thái</label>
                    <select v-model="affiliateObj.status" id="status" class="form-control">
                        <option value="PENDING">Chưa xử lý</option>
                        <option value="SUCCESS">Chấp nhận</option>
                        <option value="ERROR">Từ chối</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="message">Thông báo tới người dùng (HTML)</label>
                    <div class="row">
                        <div class="col-md-6">
                            <textarea v-model="affiliateObj.message" id="message" class="form-control"></textarea>
                        </div>
                        <div class="col-md-6">
                            <div class="message-html-container" v-html="affiliateObj.message">

                            </div>
                        </div>
                    </div>

                </div>
                <span class="attachFileBtn" @click="chooseUploadFile">Đính kèm ảnh chuyển khoản</span>
                <input id="file-upload" type="file" ref="file" @change="submitUploadAsync" style="display: none;">
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Xử lý</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import { findAsync, handleAsync } from '@/services/affiliateService';
import { uploadImageAsync } from '@/services/fileService';

export default {
    name: "AffiliateHandlePage",
    data() {
        return {
            affiliateObj: {
                withdraw_user: {},
                message: ''
            },
            qrImg: '',
            onSubmiting: false
        }
    },
    created() {
        document.title = 'Xử lý lệnh rút';
    },
    async mounted() {
        this.affiliateObj.id = this.$route.params['id'];
        const result = await findAsync(this.affiliateObj.id);

        if (result.success == true) {
            this.affiliateObj = result.data;
            console.log(this.affiliateObj);

            // GenQR
            const amount = this.affiliateObj.withdraw_point * 1000;
            const bankName = this.affiliateObj.withdraw_user.payment_bank_name;
            const bankId = this.affiliateObj.withdraw_user.payment_bank_id;

            const bankObj = {
                'ACB': 970416,
                'MBBANK': 970422,
                'Vietcombank': 970436,
                'Agribank': 970405,
                'Sacombank': 970403,
                'VPBank': 970432,
                'Techcombank': 970407,
                'VIBBank': 970441,
                'BIDV': 970418,
                'TP Bank': 970423,
                'VietinBank': 970415,
                'OCB Bank': 970448,
                'Lienvietpost Bank': 970449,
                'Oceanbank': 970414
            }

            this.qrImg = `https://img.vietqr.io/image/${bankObj[bankName]}-${bankId}-print.jpg?amount=${amount}&addInfo=gpm aff`;
        }
    },
    methods: {
        async submitAsync() {
            this.onSubmiting = true;
            const result = await handleAsync(this.affiliateObj.id, this.affiliateObj.status, this.affiliateObj.message);

            if (result.success == true) {
                this.$toast.success('Xử lý lệnh thành công');
                this.$router.push({ name: 'affiliate' });
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },
        chooseUploadFile() {
            this.$refs.file.click();
        },
        async submitUploadAsync() {
            const file = this.$refs.file.files[0];
            let fileName = this.generateGUID() + '.jpg';
            const resp = await uploadImageAsync(file, fileName);

            if (resp.success == true) {
                const backEndUrl = process.env.VUE_APP_BACKEND_URL;
                const imgUrl = backEndUrl + '/storage/images/' + resp.data.file_name;

                if (this.affiliateObj.message === null) this.affiliateObj.message = '';
                this.affiliateObj.message += `<img src='${imgUrl}' style='max-width: 300px'/>`;
            } else {
                this.$toast.error('Upload thất bại, vui lòng thử lại');
            }
        },

        // Helpers -------------------------------------------------
        generateGUID() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        }
    }
}
</script>

<style scoped>
.td-title {
    padding-right: 10px;
}

td {
    padding-bottom: 10px;
}

h5 {
    color: #0080c0;
}

.attachFileBtn {
    color: #0080c0;
    cursor: pointer;
}

#message {
    height: 100%;
    min-height: 200px;
}

#message-html-container {
    max-width: 100%;
}
</style>
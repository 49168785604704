<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <div style="display: flex;">
                    <div class="user-main-info__avatar">
                        <i v-if="!userObj.avatar" class="fas fa-user"></i>
                        <img v-else :src="backEndUrl+userObj.avatar">
                    </div> <h1 style="margin-top: 10px;">Tài khoản của tôi</h1>
                </div>
            </div>

            <!-- License info -->
            <div class="center-box">
                <h5>1. Thông tin người dùng</h5>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="email">Email <span class="cl-red">*</span></label>
                            <input v-model="userObj.email" type="text" class="form-control" id="email" disabled>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="display_name">Tên hiển thị</label>
                            <input v-model="userObj.display_name" type="text" class="form-control" id="display_name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="support_fb">Link hỗ trợ facebook</label>
                            <input v-model="supportInfos.facebook" type="text" id="support_fb" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="support_tele">Link hỗ trợ Telegram</label>
                            <input v-model="supportInfos.telegram" type="text" id="support_tele" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <input v-model="isChangePassword" type="checkbox" id="is_change_password">
                            <label for="is_change_password">&nbsp; Đổi mật khẩu</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="password">Mật khẩu mới</label>
                            <input v-model="userObj.password" autocomplete="true" type="password" id="password" class="form-control" :disabled="!isChangePassword">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="re-password">Mật lại mật khẩu mới</label>
                            <input v-model="userObj.repassword" autocomplete="true" type="password" id="re-password" class="form-control" :disabled="!isChangePassword">
                        </div>
                    </div>
                </div>
                
                <br>
            </div>

            <div class="center-box">
                <h5>2. Affiliate của tôi</h5>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="aff_code">Mã affiliate</label>
                            <input v-model="userObj.affiliate_code" type="text" class="form-control" id="aff_code" disabled>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="aff_point">Điểm affiliate</label>
                            <input v-model="userObj.affiliate_point" type="text" class="form-control" id="aff_point" disabled>
                        </div>
                    </div>
                </div>
                <br><br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Cập nhật</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Hủy bỏ</a>
            </div>
        </form>
    </div>
</template>

<script>
import { getCurrentUserAsync, updateCurrentUserAsync } from '@/services/authService';

export default {
    name: "MyAccountPage",
    data(){
        return {
            userObj: {},
            supportInfos: {
                facebook: '',
                telegram: ''
            },
            isChangePassword: false,
            backEndUrl: process.env.VUE_APP_BACKEND_URL,
            onSubmiting: false
        }
    },
    created(){
        document.title = 'My account';
    },
    async mounted() {
        const result = await getCurrentUserAsync();
        if (result.success == true) {
            this.userObj = result.data;
            if (this.userObj.support_info == null) this.userObj.support_info = '{}';
            this.supportInfos = JSON.parse(this.userObj.support_info);
            this.userObj.password = '';
            this.userObj.repassword = '';
        }
    },
    methods:{
        async submitAsync() {
            if (!this.validate())
                return;

            this.onSubmiting = true;
            this.userObj.support_info = JSON.stringify(this.supportInfos);
            const result = await updateCurrentUserAsync(this.userObj);

            if (result.success == true) {
                this.$toast.success('Cập nhật thành công');
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        validate(){
            if (this.userObj.password.length > 0 && this.userObj.password.length < 6) {
                this.$toast.error('Mật khẩu phải có từ 6 kí tự');
                return false;
            }

            if (this.userObj.password.length > 0 && this.userObj.password != this.userObj.repassword) {
                this.$toast.error('Nhập lại mật khẩu không chính xác');
                return false;
            }

            return true;
        }
    }
}
</script>

<style scoped>
.user-main-info__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #d3d3d3;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.user-main-info__avatar i {
    font-size: 28px;
    text-align: center; 
    margin-top: 8px;
    color: white;
}

.user-main-info__avatar img {
    width: 100%;
}
</style>
<template>
    <div class="form__group field">
        <input v-model="value" :placeholder="label" :id="id" :type="type" class="form__field" autocomplete="true">
        <label :for="id" class="form__label" @click="focusInput($event)">{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'AwesomeInput',
    props: ['id', 'type', 'label', 'modelValue'],
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        focusInput(event) {
            const parent = event.target.parentNode;
            const input = parent.firstChild;
            input.focus();
        }
    }
}
</script>

<style scoped>
.form__group {
    position: relative;
    padding: 25px 0 0 0;
    width: 100%;
    margin-bottom: 10px;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #ACACAC;
    outline: 0;
    font-size: 14px;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown~.form__label {
    font-size: 14px;
    cursor: text;
    top: 30px;
}

.form__label {
    position: absolute;
    top: 0px;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #9b9b9b;
}

.form__field:focus .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #2894FF;
}

.form__field:focus {
    padding-bottom: 6px;
    border-width: 3px;
    border-image: linear-gradient(to right, #2894FF, #2894FF);
    border-image-slice: 1;
}

/* reset input */

.form__field:required:invalid {
    box-shadow: none;
}</style>
<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Sửa license</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <h5>1. Thông tin license</h5>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="license">License <span class="cl-red">*</span></label>
                            <input v-model="licenseObj.license" type="text" class="form-control" id="license">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="type">Loại <span class="cl-red">*</span></label>
                            <select v-model="licenseObj.type" class="form-control" id="type" disabled>
                                <option value="LIFETIME" selected>Lifetime</option>
                                <option value="MONTHLY" selected>Monthly</option>
                                <option value="TRIAL" selected>Trial</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="add_expire_days">Thêm ngày (Eg: 10 hoặc -10)</label>
                            <input v-model="licenseObj.add_expire_days" type="number" id="add_expire_days" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="project">Dự án <span class="cl-red">*</span></label>
                            <SelectBox v-model="licenseObj.project_id" ref="selectProject" placeHolder="-- Chọn dự án" id="project" @onchange="projectChangedEvent"></SelectBox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="customer">Khách hàng <span class="cl-red">*</span></label>
                            <UserSearchBox v-model="licenseObj.owner_id" ref="selectCustomer" placeHolder="-- Chọn khách hàng" id="customer"></UserSearchBox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="limit_devices">Giới hạn thiết bị <span class="cl-red">*</span></label>
                            <input v-model="licenseObj.limit_devices" type="number" class="form-control" id="limit_devices">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="note">Ghi chú</label>
                            <input v-model="licenseObj.note" type="text" class="form-control" id="note">
                        </div>
                    </div>
                </div>
                <div class="row" v-if="uiData.hasModules">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="modules">Modules</label>

                            <div class="modules-container">
                                <div v-for="item in uiData.currentModules" :key="item.name" class="modules-container__item">
                                    <input type="checkbox" v-model="item.selected" :id="'module-'+item.name">
                                    <label :for="'module-'+item.name">{{ item.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex;">
                    <input v-model="licenseObj.is_active" type="checkbox" id="active" style="margin-right: 6px">
                    <label for="active">Kích hoạt</label>
                </div>
                <br>
            </div>

            <!-- Addination info -->
            <div class="center-box">
                <h5>2. Thông tin khác</h5>
                <hr/>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="affiliate_uid">Người giới thiệu</label>
                            <input :value="uiData.viewData.aff_customer" type="text" class="form-control" id="affiliate_uid" disabled>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="affiliate_point">Điểm giới thiệu</label>
                            <input v-model="uiData.viewData.aff_point" type="number" class="form-control" id="affiliate_point" disabled>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="expires_at">Ngày hết hạn</label>
                            <input v-model="uiData.viewData.expires_at" type="text" class="form-control" id="expires_at" disabled>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add to revenue-->
            <div class="center-box">
                <h5>
                    <input type="checkbox" @click="uiData.isAddRevenue = !uiData.isAddRevenue" id="isAddRevenue">
                    <label for="isAddRevenue" style="font-size: 1.25rem; padding-left: 5px;">Thêm vào doanh thu</label>
                </h5>
                <hr/>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="revenue_price">Giá <small>x1000</small></label>
                            <input v-model="revenueObj.price" type="number" class="form-control" id="revenue_price" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="revenue_content">Nội dung</label>
                            <input v-model="revenueObj.content" type="text" class="form-control" id="revenue_content" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="revenue_note">Ghi chú</label>
                            <input v-model="revenueObj.note" type="text" class="form-control" id="revenue_note" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Cập nhật</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import {findAsync as findLicenseAsync, updateAsync as updateLicenseAsync} from '@/services/licenseService';
import {getAsync as getProjectsAsync} from '@/services/projectService';
import {createAsync as createRevenueAsync} from '@/services/revenueService';

export default {
    name: "CreateLicensePage",
    components: {
        UserSearchBox,
        SelectBox,
    },
    data(){
        return {
            licenseObj: {  // the format same as db
                add_expire_days: 0
            },
            revenueObj: {
                customer_id: null,
                project_id: null,
                price: null,
                content: '',
                note: '',
                is_private: false
            },
            uiData: {
                hasModules: false,
                currentModules: [], // {name: '', selected: false}
                projects: [],
                viewData: {
                    aff_customer: '',
                    aff_point: 0,
                    expires_at: ''
                },
                isAddRevenue: false
            },
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Sửa license';
    },
    async mounted(){
        this.licenseObj.id = this.$route.params['id'];

        await this.loadLicenseAsync();
        await this.loadProjectSelectBoxAsync(); 
    },
    methods:{
        async submitAsync() {
            this.onSubmiting = true;

            // Standardize data
            let rawModules = [];
            this.uiData.currentModules.forEach(item => {
                if (item.selected)
                    rawModules.push(item.name);
            });
            this.licenseObj.active_modules = JSON.stringify(rawModules);

            // Insert to db
            const result = await updateLicenseAsync(this.licenseObj.id, this.licenseObj);

            if (result.success == true) {
                if (this.uiData.isAddRevenue) {
                    this.revenueObj.customer_id = this.licenseObj.owner_id;
                    this.revenueObj.project_id = this.licenseObj.project_id;
                    await createRevenueAsync(this.revenueObj);
                }

                this.$toast.success('Cập nhật thành công');
                this.$router.push({name: 'licenses'});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Events ------------------------------------------------------------------------------------
        projectChangedEvent(){
            const selectedProject = this.uiData.projects.find(item => item.id == this.licenseObj.project_id);
            if (selectedProject === undefined)
                return;

            this.uiData.currentModules = [];
            const activeModuleObjs = JSON.parse(this.licenseObj.active_modules);

            selectedProject.modules.forEach(item => {
                const selected = activeModuleObjs.filter(module => module == item.name).length > 0;
                this.uiData.currentModules.push({name: item.name, selected: selected});
            });

            this.uiData.hasModules = this.uiData.currentModules.length > 0;
        },
        // Data loader -------------------------------------------------------------------------------
        async loadLicenseAsync(){
            const result = await findLicenseAsync(this.licenseObj.id);
            if (result.success == true){
                this.licenseObj.id = result.data.id;
                this.licenseObj.license  = result.data.license;
                this.licenseObj.type = result.data.type;
                this.licenseObj.project_id = result.data.project_id;
                this.licenseObj.owner_id = result.data.owner_id;
                this.licenseObj.limit_devices = result.data.limit_devices;
                this.licenseObj.active_modules = result.data.active_modules;
                this.licenseObj.note = result.data.note;
                this.licenseObj.is_active = (result.data.is_active == 1);

                this.uiData.viewData.expires_at = result.data.expires_at;
                this.uiData.viewData.aff_point = result.data.affiliate_point;
                if (result.data.aff_customer)
                    this.uiData.viewData.aff_customer = result.data.aff_customer.display_name + ' - ' + result.data.aff_customer.email;

                this.$refs.selectCustomer.setSelectedId(result.data.owner_id, result.data.customer.display_name + ' - ' + result.data.customer.email);
            }
        },
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.uiData.projects = result.data.data;
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id', this.licenseObj.project_id);
            }
        },
    }
}
</script>

<style scoped>
.modules-container {
    display: flex;
    flex-wrap: wrap;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}

.modules-container__item {
    padding: 5px 15px 5px 0px;
}

.modules-container__item input {
    margin-right: 6px;
}
</style>
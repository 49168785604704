<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Tạo đơn hàng</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <h5>1. Thông tin đơn hàng</h5>
                <hr>

                <!-- Selectbox -->
                <div class="radioLicenseModeContainer">
                    <input type="radio" id="radioLicenseModeNew" name="licenseMode" @click="licenseMode = 'NEW'" checked>
                    <label for="radioLicenseModeNew" style="margin-right: 20px;">License mới</label>

                    <input type="radio" id="radioLicenseModeAppend" name="licenseMode" @click="licenseMode = 'APPEND'">
                    <label for="radioLicenseModeAppend">Cập nhật vào license cũ</label>
                </div>

                <!-- New license mode -->
                <div class="licenseMode__new" v-show="licenseMode === 'NEW'">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="trans_id">Mã đơn hàng <span class="cl-red">*</span></label>
                                <input v-model="orderObj.transfer_content" type="text" class="form-control" id="trans_id">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label for="customer">Khách hàng <span class="cl-red">*</span></label>
                                <UserSearchBox v-model="orderObj.customer_id" placeHolder="-- Chọn khách hàng" id="customer"></UserSearchBox>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label for="project">Dự án<span class="cl-red">*</span></label>
                                <SelectBox v-model="orderObj.project_id" ref="selectProject" placeHolder="-- Chọn dự án" id="project" @onchange="projectChangedEvent"></SelectBox>
                            </div>
                        </div>
                    </div>

                    <div class="projectPackageContainer">
                        <div class="form-group">
                            <label for="modules">Gói dự án</label>

                            <select v-model="orderObj.project_package_id" class="form-control" @change="projectPackageChangedEvent">
                                <option v-for="item in uiData.projectPackages" v-bind:key="item.id" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Append to license mode-->
                <div class="licenseMode-append" v-show="licenseMode === 'APPEND'">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="trans_id">Mã đơn hàng <span class="cl-red">*</span></label>
                                <input v-model="orderObj.transfer_content" type="text" class="form-control" id="trans_id">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="license">License <span class="cl-red">*</span></label>
                                <input v-model="modifyLicenseObj.license" class="form-control" id="license" @input="modifyLicenseChangedEvent">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group">
                                <label for="licenseInfo">Thông tin license 
                                    <div v-show="modifyLicenseObj.isLoading" class="spinner-border text-secondary spinner-border-sm" role="status" style="margin-left: 10px;">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </label>
                                <input v-model="modifyLicenseObj.info" class="form-control" id="licenseInfo" disabled>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modules -->
                <div v-if="uiData.projectModules.length > 0" class="projectModuleContainer">
                    <div class="form-group">
                        <label for="modules">Modules</label>

                        <div class="modules-container">
                            <div v-for="item in uiData.projectModules" :key="item.name" class="modules-container__item">
                                <input type="checkbox" v-model="item.selected" :id="'module-'+item.name" :disabled="item.disabled">
                                <label :for="'module-'+item.name">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <!-- Payment -->
            <div class="center-box">
                <h5>2. Thanh toán</h5>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="payment_type">Kiểu thanh toán <span class="cl-red">*</span></label>
                            <select v-model="orderObj.payment_type" class="form-control" id="payment_type">
                                <option value="BANK">Chuyển khoản</option>
                                <option value="AFF_POINT">Trừ từ điểm Affiliate</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="affiliate_code">
                                Affiliate code
                                <div v-show="onLoadingAffInfo" class="spinner-border text-secondary spinner-border-sm" role="status" style="margin-left: 5px;">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </label>
                            <input v-model="orderObj.affiliate_code" class="form-control" id="affiliate_code" @input="affCodeChangedEvent">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="price">Price <span class="cl-red">*</span> <small>x1000 vnd</small></label>
                            <input v-model="orderObj.actual_price" class="form-control" id="price" required>
                        </div>
                    </div>
                </div>
                <div style="display: flex;">
                    <input v-model="orderObj.is_transfered" type="checkbox" id="is_transfered" style="margin-right: 6px">
                    <label for="is_transfered">Đã chuyển khoản</label>
                </div>
                <br>
                <small style="color: #ff8040"><i class="fas fa-exclamation-triangle"></i> Chú ý: Bạn không thể sửa khi đơn hàng đã tạo ! Nếu nhập chính xác Affiliate code, 10% hoa hồng sẽ được cộng vào tài khoản người giới thiệu tương ứng</small>
            </div>


            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo đơn hàng</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import {createAsync as createOrderAsync, getRandomTransferContentAsync, getAffiliateInfoAsync} from '@/services/orderService';
import {getAsync as getProjectsAsync} from '@/services/projectService';
import { getAsync as getLicensesAsync } from '@/services/licenseService';

export default {
    name: "CreateOrderPage",
    components: {
        UserSearchBox,
        SelectBox,
    },
    computed: {
        projectModuleSelected() {
            return this.uiData.projectModules.map(item => item.selected);
        }
    },
    watch: {
        projectModuleSelected() {
           this.caculatePrice();
        },
        licenseMode(){
            this.uiData.projectModules = [];
            this.modifyLicenseObj.license = '';
            this.modifyLicenseObj.info = '';
        }
    },
    data(){
        return {
            orderObj: {
                transfer_content: '',
                customer_id: null,
                project_id: null,
                project_module_names: [],
                project_package_id : null,
                payment_type: 'BANK',
                actual_price: null,
                is_transfered: false,
                modify_license_id: null,
                affiliate_code: ''
            },
            uiData: {
                projectModules: [], // {name: '', selected: false}
                projectPackages: [],
                projects: []
            },
            licenseMode: 'NEW',
            modifyLicenseObj: {
                license: '',
                info: '',
                isLoading: false
            },
            onLoadingAffInfo: false,
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Create order';
    },
    async mounted(){
        this.orderObj.transfer_content = await getRandomTransferContentAsync();
        await this.loadProjectSelectBoxAsync();
    },
    methods:{
        async submitAsync() {
            this.onSubmiting = true;

            // Standardize data
            let slectedModulesName = [];
            this.uiData.projectModules.forEach(item => {
                if (item.selected)
                slectedModulesName.push(item.name);
            });
            this.orderObj.project_module_names = slectedModulesName;
            this.orderObj.modify_license_mode = this.licenseMode;

            // Insert to db
            const result = await createOrderAsync(this.orderObj);

            if (result.success == true) {
                this.$toast.success('Tạo đơn hàng thành công');
                this.$router.push({name: 'orders'});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Events ------------------------------------------------------------------------------------
        projectChangedEvent(){
            const selectedProject = this.uiData.projects.find(item => item.id == this.orderObj.project_id);
            if (selectedProject === undefined)
                return;

            this.uiData.projectModules = [];
            this.uiData.projectPackages = [];

            selectedProject.modules.forEach(item => {
                this.uiData.projectModules.push({name: item.name, price: item.price, selected: false});
            });

            this.uiData.projectPackages = selectedProject.packages;
            if (this.uiData.projectPackages.length > 0) {
                this.orderObj.project_package_id = this.uiData.projectPackages[0].id;
                this.orderObj.actual_price = this.uiData.projectPackages[0].price;
            }
        },

        projectPackageChangedEvent() {
            this.caculatePrice();
        },

        caculatePrice() {
            try {
                if (this.licenseMode == 'NEW'){
                    const selectPackage = this.uiData.projectPackages.find(item => item.id == this.orderObj.project_package_id);
                    this.orderObj.actual_price = parseInt(selectPackage.price);
                } else {
                    this.orderObj.actual_price = 0;
                }
                
                const selectedModules = this.uiData.projectModules.filter(item => item.selected && !item.disabled);
                selectedModules.forEach(item => {
                    this.orderObj.actual_price += parseInt(item.price);
                });
            // eslint-disable-next-line no-empty
            } catch {

            }
        },

        async affCodeChangedEvent(){
            if (this.licenseMode == 'APPEND')
                return;

            if (!this.orderObj.affiliate_code) {
                this.caculatePrice();
                this.onLoadingAffInfo = false;
                return;
            }
            
            this.caculatePrice();
            this.onLoadingAffInfo = true;
            const checker = await getAffiliateInfoAsync(this.orderObj.affiliate_code);
            
            if (checker.success == true) {
                const saleOffPercent = parseInt(process.env.VUE_APP_AFFILIATE_PERCENT_FOR_BUYER);
                if (this.orderObj.actual_price > 0){
                    this.orderObj.actual_price = (100 - saleOffPercent) * this.orderObj.actual_price / 100;
                }
            }
            
            this.onLoadingAffInfo = false;
        },

        async modifyLicenseChangedEvent(){
            if (!this.modifyLicenseObj.license)
                return;

            this.modifyLicenseObj.isLoading = true;
            const result = await getLicensesAsync(1, 1, this.modifyLicenseObj.license);
            this.modifyLicenseObj.isLoading = false;

            if (result.success) {
                if (result.data.data.length > 0) {
                    const licenseObj = result.data.data[0];
                    const activeModules = JSON.parse(licenseObj.active_modules);

                    this.orderObj.modify_license_id = licenseObj.id;
                    this.modifyLicenseObj.info = licenseObj.project.name + ` (${licenseObj.limit_devices} thiết bị) / ` + licenseObj.customer.display_name;
                
                    // Load project package
                    const selectedProject = this.uiData.projects.find(item => item.id == licenseObj.project_id);
                    this.uiData.projectModules = [];

                    selectedProject.modules.forEach(item => {
                        const isModuleSelected = activeModules.includes(item.name);
                        this.uiData.projectModules.push({name: item.name, price: item.price, selected: isModuleSelected, disabled: isModuleSelected});
                    });  
                } else {
                    this.modifyLicenseObj.info = 'Không tìm thấy thông tin';
                }
                        
            }
        },

        // Data loader -------------------------------------------------------------------------------
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.uiData.projects = result.data.data;
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');
            }
        }
    }
}
</script>

<style scoped>
.modules-container {
    display: flex;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}

.modules-container__item {
    padding: 5px 15px 5px 0px;
}

.modules-container__item input {
    margin-right: 6px;
}

.radioLicenseModeContainer input {
    margin-right: 5px;
    margin-bottom: 30px;
}
</style>
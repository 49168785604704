const axios = require('axios').default;
const TOKEN_NAME = 'token';


const httpGetAsync = async (url) => {
    try {
        const token = localStorage.getItem(TOKEN_NAME);
        const resp = await axios.get(url, {
            headers:{
                Authorization : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        // Auto redirect to login page if the token is invalid
        if (!resp.data.success && resp.data.message.includes('cần phải đăng nhập')){
            document.location = '/login';
        }
        return resp;
    } catch (error){
        if (error.message.includes('401')){
            localStorage.removeItem(TOKEN_NAME);
            localStorage.removeItem('AUTH_CACHE');
            document.location = '/login';
        }
        return null;
    }
}

const httpPostAsync = async (url, data) => {
    try {
        const token = localStorage.getItem(TOKEN_NAME);
        const resp = await axios.post(url, data, {
            headers:{
                Authorization : `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return resp;
    } catch (error){
        if (error.message.includes('401')){
            localStorage.removeItem(TOKEN_NAME);
            localStorage.removeItem('AUTH_CACHE');
            document.location = '/login';
        }
        return null;
    }
}

const httpUploadAsync = async (url, file, fileName) => {
    try {
        const token = localStorage.getItem(TOKEN_NAME);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_name', fileName);

        const resp = await axios.post(url, formData, {
            headers:{
                Authorization : `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        return resp;
    } catch (error){
        if (error.message.includes('401')){
            localStorage.removeItem(TOKEN_NAME);
            localStorage.removeItem('AUTH_CACHE');
            document.location = '/login';
        }
        return null;
    }
}


export {httpGetAsync, httpPostAsync, httpUploadAsync, TOKEN_NAME};
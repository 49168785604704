import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import store from './store';
import ToastPlugin from 'vue-toast-notification';

import '../node_modules/nprogress/nprogress.css';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(ToastPlugin);
app.mount('#app');

<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Thêm người dùng</h1>
            </div>

            <!-- User info -->
            <div class="center-box">
                <h5>1. Thông tin chung</h5>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="email">Email <span class="cl-red">*</span></label>
                            <input v-model="userObj.email" type="email" class="form-control" id="email">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="password">Mật khẩu <span class="cl-red">*</span></label>
                            <input v-model="userObj.password" type="password" class="form-control" id="password">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="repassword">Nhập lại mật khẩu <span class="cl-red">*</span></label>
                            <input v-model="userObj.repassword" type="password" class="form-control" id="repassword">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="display_name">Tên hiển thị <span class="cl-red">*</span></label>
                            <input v-model="userObj.display_name" type="text" class="form-control" id="display_name">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="phone_number">Số điện thoại</label>
                            <input v-model="userObj.phone_number" type="text" class="form-control" id="phone_number">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="tax_code">Mã số thuế</label>
                            <input v-model="userObj.tax_code" type="text" class="form-control" id="tax_code">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="address">Địa chỉ (4 cấp: địa chỉ, xã phường, huyện thị trấn, tỉnh; các thông tin cần chính xác như trang user). Địa chỉ không được có dấu ','</label>
                            <input v-model="userObj.address" type="text" class="form-control" id="address">
                        </div>
                    </div>
                </div>
                <div style="display: flex;">
                    <input v-model="userObj.is_active" type="checkbox" id="active" style="margin-right: 6px">
                    <label for="active">Kích hoạt</label>
                </div>
                <br>
            </div>

            <!-- Role -->
            <div class="center-box">
                <h5>2. Quyền hệ thống</h5>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="system_role">Phân quyền <span class="cl-red">*</span></label>
                            <select v-model="userObj.system_role" id="system_role" class="form-control">
                                <option value="ADMIN">Quản trị viên</option>
                                <option value="EMPLOYEE">Nhân viên</option>
                                <option value="CUSTOMER">Khách hàng</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Support -->
            <div class="center-box">
                <h5>3. Hỗ trợ</h5>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="facebook">Facebook</label>
                            <input v-model="userObj.support_info.facebook" type="text" class="form-control" id="facebook">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telegram">Telegram</label>
                            <input v-model="userObj.support_info.telegram" type="text" class="form-control" id="telegram">
                        </div>
                    </div>
                </div>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Thêm người dùng</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import { createAsync as createUserAsync} from '@/services/userService';

export default {
    name: "CreateUserPage",
    data(){
        return {
            userObj: {
                email: '',
                password: '',
                repassword: '',
                support_info: {
                    facebook: '',
                    telegram: ''
                },
                system_role: 'CUSTOMER',
                is_active: true
            },
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Thêm người dùng';
    },
    async mounted(){

    },
    methods:{
        async submitAsync() {
            if (!this.validate())
                return;

            this.onSubmiting = true;
            const result = await createUserAsync(this.userObj);

            if (result.success == true) {
                this.$toast.success(`Người dùng [${this.userObj.email}] được thêm thành công`);
                this.$router.push({name: 'users', query: {search: this.userObj.email}});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Helper ------------------------------------------------------------------------------------
        validate(){
            if (this.userObj.password.length < 6) {
                this.$toast.error('Mật khẩu phải có ít nhất 6 kí tự');
                return false;
            }

            if (this.userObj.password != this.userObj.repassword) {
                this.$toast.error('Mật khẩu nhập lại không chính xác');
                return false;
            }

            return true;
        }
    }
}
</script>

<style scoped>
.modules-container {
    display: flex;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}

.modules-container__item {
    padding: 5px 15px 5px 0px;
}

.modules-container__item input {
    margin-right: 6px;
}
</style>
<template>
    <div>
        <div class="title-wrapper">
            <h1>Lịch sử quản trị ({{ total }})</h1>
            <div>
                <button type="button" class="btn btn-danger" @click="showRemoveHistoryModal">Xóa lịch sử</button>
            </div>
        </div>

        <!-- Data table -->
        <div class="data-table">
            <table>
                <colgroup>
                    <col width="30%">
                    <col width="70%">
                </colgroup>
                <thead>
                    <tr>
                        <th>Thời gian</th>
                        <th>Log</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="2">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td>{{ formatDateTimeStr(item.created_at) }}</td>
                        <td v-html="item.content"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>

        <!-- remove history modal -->
        <Transition name="bounce">
            <div ref="removeHistoryModal" class="remove-history-modal" v-show="removeObj.isShowRemoveHistoryModal">
                <h5>Xóa lịch sử</h5>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="dateStart">Ngày bắt đầu</label>
                            <input v-model="removeObj.startDate" type="date" class="form-control" id="dateStart">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="dateEnd">Ngày kết thúc</label>
                            <input v-model="removeObj.endDate" type="date" class="form-control" id="dateEnd">
                        </div>
                    </div>
                </div>
                <button class="btn btn-danger" type="button" @click="removeHistoryAsync">Xóa</button>
                <span aria-hidden="true" class="close" @click="closeRemoveHistoryModal">&times;</span>
            </div>
        </Transition>
    </div>
</template>
  
<script>
import * as db from '@/services/historyDataService';
import { calcPaginationPageList, calcPageAfterDelete } from '@/helpers/paginationHelper';
import { formatDateTimeString } from '@/helpers/strHelper';
import NProgress from 'nprogress';

export default {
    name: 'SystemHistoryPage',
    components: {
    },
    created() {
        document.title = 'System history';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.per_page) this.filter.per_page = 30;
        if (!this.filter.page) this.filter.page = 1;
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
        document.addEventListener('click', this.handleClickOutsideModal);
    },
    data() {
        return {
            data: [],
            filter: {
                page: 1,
                per_page: 30
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            removeObj: {
                isShowRemoveHistoryModal: false,
                removeHistoryModalLoaded: false, // Wait for transition done
                startDate: '',
                endDate: ''
            },
            inLoading: true
        }
    },
    methods: {
        async removeHistoryAsync() {
            if (confirm(`Bạn chắc chắn muốn xóa lịch sử đã chọn?`)) {
                NProgress.start();

                const result = await db.bulkRemoveAsync(this.removeObj.startDate, this.removeObj.endDate);
                if (result.success) {
                    // Reload after
                    // Note: we must re-cacluate the page
                    const deletedCount = parseInt(result.data);
                    this.filter.page = calcPageAfterDelete(this.total, deletedCount, this.filter.page, this.filter.per_page);
                    this.closeRemoveHistoryModal();

                    await this.loadAsync();
                    this.$toast.success('Xóa lịch sử thành công');
                } else
                    this.$toast.error(result.message);
                
                NProgress.done();
            }
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                    window.scrollTo({ top: 0, behavior: 'smooth' });

            const result = await db.getAsync(this.filter.page, this.filter.per_page, this.filter);

            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // UI event ------------------------------------------------------------------------------------
        showRemoveHistoryModal(){
            this.removeObj.isShowRemoveHistoryModal = true;
            setTimeout(() => {
                this.removeObj.removeHistoryModalLoaded = true; // Wait transition
            }, 500);
        },
        closeRemoveHistoryModal() {
            this.removeObj.isShowRemoveHistoryModal = false;
            this.removeObj.removeHistoryModalLoaded = false;
        },
        handleClickOutsideModal(event) {
            if (!this.$refs.removeHistoryModal)
                return;

            const modalEl = this.$refs.removeHistoryModal;
            if (this.removeObj.isShowRemoveHistoryModal && this.removeObj.removeHistoryModalLoaded && !modalEl.contains(event.target)) {
                this.closeRemoveHistoryModal();
            }
        },
        formatDateTimeStr(datetimeStr){
            return formatDateTimeString(datetimeStr);
        }
    }
    
}
</script>
  
<style scoped>
.remove-history-modal {
    position: fixed;
    max-width: 90vw;
    width: 800px;
    top: 100px;
    background: white;
    left: calc(50% - 400px);
    padding: 15px 15px 50px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px #adadad;
    z-index: 99999;
}


.remove-history-modal .close {
    position: absolute;
    right: 15px;
    top: 10px;
    border: none;
    background: white;
    font-size: 22px;
    cursor: pointer;
    color: #555555;
}


/* Transition */
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Thêm license</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <h5>1. Thông tin License</h5>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="license">License <span class="cl-red">*</span></label>
                            <input v-model="licenseObj.license" type="text" class="form-control" id="license">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="type">Type <span class="cl-red">*</span></label>
                            <select v-model="licenseObj.type" class="form-control" id="type" @change="typeChangedEvent">
                                <option value="LIFETIME" selected>Lifetime</option>
                                <option value="MONTHLY" selected>Monthly</option>
                                <option value="TRIAL" selected>Trial</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="expires_days">Expires days</label>
                            <input v-model="licenseObj.expires_days" type="number" id="expires_days" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="project">Dự án <span class="cl-red">*</span></label>
                            <SelectBox v-model="licenseObj.project_id" ref="selectProject" placeHolder="-- Chọn dự án" id="project" @onchange="projectChangedEvent"></SelectBox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="customer">Customer <span class="cl-red">*</span></label>
                            <UserSearchBox v-model="licenseObj.owner_id" placeHolder="-- Chọn khách hàng" id="customer"></UserSearchBox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="limit_devices">Giới hạn thiết bị <span class="cl-red">*</span></label>
                            <input v-model="licenseObj.limit_devices" type="text" class="form-control" id="limit_devices">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="note">Ghi chú</label>
                            <input v-model="licenseObj.note" type="text" class="form-control" id="note">
                        </div>
                    </div>
                </div>
                <div class="row" v-if="uiData.hasModules">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="modules">Modules</label>

                            <div class="modules-container">
                                <div v-for="item in uiData.currentModules" :key="item.name" class="modules-container__item">
                                    <input type="checkbox" v-model="item.selected" :id="'module-'+item.name">
                                    <label :for="'module-'+item.name">{{ item.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex;">
                    <input v-model="licenseObj.is_active" type="checkbox" id="active" style="margin-right: 6px">
                    <label for="active">Kích hoạt</label>
                </div>
                <br>
            </div>

            <!-- Add to revenue-->
            <div class="center-box">
                <h5>
                    <input type="checkbox" @click="uiData.isAddRevenue = !uiData.isAddRevenue" id="isAddRevenue">
                    <label for="isAddRevenue" style="font-size: 1.25rem; padding-left: 5px;">Thêm vào doanh thu</label>
                </h5>
                <hr/>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="revenue_price">Giá <small>x1000</small></label>
                            <input v-model="revenueObj.price" type="number" class="form-control" id="revenue_price" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="revenue_content">Nội dung</label>
                            <input v-model="revenueObj.content" type="text" class="form-control" id="revenue_content" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="revenue_note">Ghi chú</label>
                            <input v-model="revenueObj.note" type="text" class="form-control" id="revenue_note" :disabled="!uiData.isAddRevenue">
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo license</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';

import {createAsync as createLicenseAsync, getRandomLicenseAsync} from '@/services/licenseService';
import {getAsync as getProjectsAsync} from '@/services/projectService';
import {createAsync as createRevenueAsync} from '@/services/revenueService';

export default {
    name: "CreateLicensePage",
    components: {
        UserSearchBox,
        SelectBox,
    },
    data(){
        return {
            licenseObj: {
                license: '',
                type: 'LIFETIME',
                project_id: null,
                owner_id: null,
                limit_devices: 5,
                active_modules: [],
                note: '',
                affiliate_uid: null,
                affiliate_point: null,
                expires_days: 10000, // lifetime
                is_active: true
            },
            revenueObj: {
                customer_id: null,
                project_id: null,
                price: null,
                content: '',
                note: '',
                is_private: false
            },
            uiData: {
                hasModules: false,
                currentModules: [], // {name: '', selected: false}
                projects: [],
                viewData: {
                    aff_customer: '',
                    aff_point: 0,
                    expires_at: ''
                },
                isAddRevenue: false
            },
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Tạo license';
    },
    async mounted(){
        await this.genLicenseCodeAsync();
        await this.loadProjectSelectBoxAsync(); 
    },
    methods:{
        async submitAsync() {
            if (!this.validate())
                return;

            this.onSubmiting = true;

            // Standardize data
            let rawModules = [];
            this.uiData.currentModules.forEach(item => {
                if (item.selected)
                    rawModules.push(item.name);
            });
            this.licenseObj.active_modules = JSON.stringify(rawModules);

            // Insert to db
            const result = await createLicenseAsync(this.licenseObj);

            if (result.success == true) {
                if (this.uiData.isAddRevenue) {
                    this.revenueObj.customer_id = this.licenseObj.owner_id;
                    this.revenueObj.project_id = this.licenseObj.project_id;
                    await createRevenueAsync(this.revenueObj);
                }

                this.$toast.success('Tạo license thành công');
                this.$router.push({name: 'licenses'});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Events ------------------------------------------------------------------------------------
        projectChangedEvent(){
            const selectedProject = this.uiData.projects.find(item => item.id == this.licenseObj.project_id);
            console.log(this.licenseObj.project_id);
            if (selectedProject === undefined)
                return;

            this.uiData.currentModules = [];

            selectedProject.modules.forEach(item => {
                this.uiData.currentModules.push({name: item.name, selected: false});
            });

            this.uiData.hasModules = this.uiData.currentModules.length > 0;
        },
        typeChangedEvent(){
            if (this.licenseObj.type == 'LIFETIME') this.licenseObj.expires_days = 10000;
            else if (this.licenseObj.type == 'MONTHLY') this.licenseObj.expires_days = 31;
            else if (this.licenseObj.type == 'TRIAL') this.licenseObj.expires_days = 7;
        },

        // Data loader -------------------------------------------------------------------------------
        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.uiData.projects = result.data.data;
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');
            }
        },

        async genLicenseCodeAsync(){
            this.licenseObj.license = await getRandomLicenseAsync();
        },

        // Validate data -----------------------------------------------------------------------------
        validate() {
            if (!this.licenseObj.project_id){
                this.$toast.error('Vui lòng chọn dự án');
                return false;
            }

            if (!this.licenseObj.owner_id) {
                this.$toast.error('Vui lòng chọn khách hàng');
                return false;
            }
            
            return true;
        }
    }
}
</script>

<style scoped>
.modules-container {
    display: flex;
    flex-wrap: wrap;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}

.modules-container__item {
    padding: 5px 15px 5px 0px;
}

.modules-container__item input {
    margin-right: 6px;
}
</style>
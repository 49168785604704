<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Thêm license số lượng lớn</h1>
            </div>

            <!-- License info -->
            <div class="center-box">
                <small style="color: #0080ff">Chúng tôi sẽ tạo dạng license vĩnh viễn cho các dự án được chọn</small>
                <br/><br/>
                <div class="form-group">
                    <label for="customer">Khách hàng <span class="cl-red">*</span></label>
                    <UserSearchBox v-model="backendData.customer_id" placeHolder="-- Chọn khách hàng" id="customer"></UserSearchBox>
                </div>
                <div class="form-group">
                    <label for="projects">Dự án <span class="cl-red">*</span></label>
                    <div class="project-container">
                        <div v-for="project in uiData.projects" v-bind:key="project.id" style="margin-bottom: 5px;">
                            <input type="checkbox" v-model="project.selected" :id="'project-'+project.id">
                            <label :for="'project-'+project.id">{{ project.name }}</label>
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo mới</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import UserSearchBox from '@/components/UserSearchBox.vue';

import {bulkCreateAsync} from '@/services/licenseService';
import {getAsync as getProjectsAsync} from '@/services/projectService';

export default {
    name: "CreateBulkLicensePage",
    components: {
        UserSearchBox,
    },
    data(){
        return {
            backendData: {
                customer_id: undefined,
                project_ids: []
            },
            uiData: {
                projects: []
            },
            error: '',
            onSubmiting: false
        }
    },
    created(){
        document.title = 'Thêm license số lượng lớn';
    },
    async mounted(){
        await this.loadProjectAsync();
    },
    methods:{
        async submitAsync() {
            this.onSubmiting = true;

            // Insert to db
            this.backendData.project_ids = this.uiData.projects.filter(item => item.selected).map(item => item.id);
            const result = await bulkCreateAsync(this.backendData);

            if (result.success == true) {
                this.error = '';
                this.$toast.success(`${result.data.length} licenses đã được tạo thành công`);
                this.$router.push({name: 'licenses'});
            } else {
                this.error = result.data;
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Data loader -------------------------------------------------------------------------------
        async loadProjectAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc');
            if (result.success == true){
                this.uiData.projects = result.data.data;
            }
        }
    }
}
</script>

<style scoped>
.project-container {
    max-height:400px;
    height: 400px;
    overflow:auto; 
    border: 1px solid #D8D8D8; 
    border-radius: 5px;
    padding: 15px;
}

.project-container input {
    margin-right: 5px;
}
</style>
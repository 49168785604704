<template>
    <div class="main-container">
        <div v-if="isLoading" class="loading-overlay">
            <div class="loading-spinner"></div>
        </div>
        <LeftMenu></LeftMenu>
        
        <div class="content-wrapper">
            <div class="top-wrapper sticky-top">
                <div class="left-wrapper">
                    <span class="systemHealth" v-if="!isMobile()">Hệ thống: Rất tốt</span>
                    <span v-else class="systemHealth systemHealth--warning">Cảnh báo: giao diện không hiển thị tốt trên điện thoại</span>
                </div>
                <div class="user-option-container">
                    <span @click="toogleUserMenuOption">Xin chào <b>{{ currentUserLastName }}</b> <i class="fas fa-caret-down"></i></span>
                    <ul ref="userMenuOption" v-show="isShowUserMenuOption" class="user-option-container__menu">
                        <li><router-link :to="{name: 'my-account'}" @click="closeUserMenuOption"><i class="fas fa-info-circle"></i> Thông tin</router-link></li>
                        <li><router-link :to="{name: 'my-account'}" @click="closeUserMenuOption"><i class="fas fa-user-lock"></i> Bảo mật</router-link></li>
                        <li><a href="#" @click="userLogoutAsync($event)"><i class="fas fa-sign-out-alt"></i> Đăng xuất</a></li>
                    </ul>
                </div>
            </div>
            <div class="web-content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { logoutAsync } from '@/services/authService';
import LeftMenu from '../components/LeftMenu.vue';

export default {
    name: "AdminLayout",
    components: {
        LeftMenu
    },
    data(){
        return {
            isLoading: true,
            isShowUserMenuOption: false,
            isUserMenuLoaded: false,
            currentUserLastName: this.$store.getters.getCurrentUserLastName
        }
    },
    mounted(){
        document.addEventListener('click', this.handleClickOutsidePopup);
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },
    methods: {
        async userLogoutAsync(e) {
            await logoutAsync();
            this.$router.push('/login');
            e.preventDefault();
        },
        toogleUserMenuOption(){
            this.isShowUserMenuOption = !this.isShowUserMenuOption;

            if (this.isShowUserMenuOption) {
                setTimeout(() => {
                    this.isUserMenuLoaded = true;
                }, 100);
            } else
                this.isUserMenuLoaded = false;
        },
        closeUserMenuOption() {
            this.isShowUserMenuOption = false;
            this.isUserMenuLoaded = false;
        },
        handleClickOutsidePopup(event) {
            if (!this.$refs.userMenuOption)
                return;

            const popupEl = this.$refs.userMenuOption;

            if (this.isUserMenuLoaded && this.isShowUserMenuOption && !popupEl.contains(event.target)) {
                this.isShowUserMenuOption = false;
                this.isUserMenuLoaded = false;
            }
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
};
</script>

<style>
.top-wrapper {
    width: 100%;
    height: 55px;
    padding: 15px;
    background: white;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    justify-content: space-between;
}

.toogleLeftMenu-btn {
    cursor: pointer;
}

.user-option-container {
    position: relative;
    cursor: pointer;
}

.user-option-container__menu {
    position: absolute;
    top: 45px;
    right: 0px;
    list-style-type: none;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin: 0;
    padding: 15px 0;
    width: 150px;
}

.user-option-container__menu li {
    margin: 0;
    padding: 0;
}

.user-option-container__menu li a {
    display: block;
    padding: 5px 15px;
    color: #404040;
}

.user-option-container__menu li a:hover {
    color: #787878;
}

.user-option-container__menu li a i {
    margin-right: 5px;
}

.content-wrapper {
    z-index: 1000;
    min-height: 105vh; /* Scrollbar alway visiable */
}

.systemHealth {
    color: #00ac2c;
}

.systemHealth--warning {
    color: #fd7e00 !important;
}

/* Loading */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
import { httpGetAsync, httpPostAsync} from "./requestService";

export const getAsync = async (page, perPage, search, orderBy, orderByType, selectCols = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects?page=${page}&per_page=${perPage}&search=${search}&order_by=${orderBy}&order_by_type=${orderByType}&select_columns=${selectCols}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createAsync = async (projectObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects/create`;
    const postData = JSON.stringify(projectObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateAsync = async (id, projectObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects/edit/${id}`;
    const postData = JSON.stringify(projectObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const countActiveDevicesAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/projects/active-devices-count/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}
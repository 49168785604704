/**
 * Caculate pagination list page
 * 
 * @param {*} currentPage 
 * @param {*} totalPage 
 * @returns {Array} [4,5,6,7,8,9,10]
 */
export const calcPaginationPageList = (currentPage, totalPage) => {
    const MAX_PAGE = 9;
    let paginationPageList = [];

    for (let page = Math.max(1, currentPage-4); page <= currentPage; page++){
        paginationPageList.push(page);
    }

    for (let page = currentPage + 1; page <= totalPage; page++){
        paginationPageList.push(page);
        if (paginationPageList.length == MAX_PAGE)
            break;
    }

    return paginationPageList;
}

export const calcPageAfterDelete = (totalData, deletedCount, currentPage, perPage) => {
    let page = currentPage;
    const actualTotalPage = Math.ceil((totalData - deletedCount) / perPage);
    if (page > actualTotalPage) page = actualTotalPage;

    return page;
}
<template>
    <Transition name="bounce">
        <div class="device-modal">
            <h5>Thiết bị đã active</h5>
            <hr>
            <button id="reset-btn" type="button" class="btn btn-danger" @click="resetDevicesAsync($event)">Đặt lại</button>
            <br><br>
            <div class="table-container">
                <table class="data-table">
                    <colgroup>
                        <col width="25%">
                        <col width="25%">
                        <col width="25%">
                        <col width="25%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Device ID</th>
                            <th>Tên máy</th>
                            <th>Thời gian active đầu</th>
                            <th>Thời gian active cuối cùng</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in activeDevices" v-bind:key="item.id">
                            <td>{{ item.device_id }}</td>
                            <td>{{ item.machine_name }}</td>
                            <td>{{ item.first_active_at }}</td>
                            <td>{{ item.last_active_at }}</td>
                        </tr>
                        <tr>
                            <td colspan="4" style="text-align: center;" v-show="state.isShowNoDataRow">No data</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <span aria-hidden="true" class="close" @click="closeModal">&times;</span>
        </div>
    </Transition>
</template>

<script>
/* eslint-disable */
import {getActiveDevicesAsync, resetActiveDevicesAsync} from '@/services/licenseService';

export default {
    name: 'ActiveDeviceModal',
    data() {
        return {
            licenseId: undefined,
            activeDevices: [],
            rowItem: {}, // row item in index page to reset active_devices_count on UI,
            dataLoaded: false, // Use for index page handle close modal when outsite clicked
            state: {
                isShowNoDataRow: false,
            }
        }
    },
    methods: {
        async resetDevicesAsync(event){
            event.target.classList.remove('btn-danger');
            event.target.classList.add('btn-secondary');
            event.target.innerText = 'Đang đặt lại'
            event.target.disabled = true;
            
            const result = await resetActiveDevicesAsync(this.licenseId);
            if (result){
                this.activeDevices = [];
                this.rowItem.active_devices_count = 0;
                this.$emit('deviceReseted');
            } else {
                this.$toast.error('Lỗi không xác định. Vui lòng thử lại');
            }
            
            event.target.innerText = 'Đặt lại';
            event.target.classList.remove('btn-secondary');
            event.target.classList.add('btn-danger');
            event.target.disabled = false;
        },
        async loadDataAsync(rowItem){
            this.dataLoaded = false;
            const activeDevices = await getActiveDevicesAsync(rowItem.id);
            this.rowItem = rowItem;
            this.licenseId = rowItem.id;
            this.activeDevices = activeDevices;
            this.state.isShowNoDataRow = (activeDevices.length == 0);

            this.dataLoaded = true;
        },
        clearData(){
            this.rowItem = undefined;
            this.licenseId = undefined;
            this.activeDevices = [];
            this.state.isShowNoDataRow = false;
        },
        closeModal(){
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.device-modal {
    position: fixed;
    max-width: 90vw;
    width: 1000px;
    top: 50px;
    background: white;
    left: calc(50% - 500px);
    padding: 15px 15px 30px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    min-height: 400px;
    box-shadow: 0 0 5px 2px #adadad;
    z-index: 99999;
}

.table-container {
    max-height: 400px;
    overflow: auto;
}

.device-modal .close {
    position: absolute;
    right: 15px;
    top: 10px;
    border: none;
    background: white;
    font-size: 22px;
    cursor: pointer;
    color: #555555;
}

#reset-btn {
    width: 130px;
}

/* Transition */
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
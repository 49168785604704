import { httpGetAsync, httpPostAsync } from "./requestService";

/**
 *  Get data list
 * @param {*} page 
 * @param {*} perPage 
 * @returns 
 */
export const getAsync = async (page, perPage) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/system-history?page=${page}&per_page=${perPage}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/system-history/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const bulkRemoveAsync = async (startDate, endDate) => {
    const data = JSON.stringify({start_date: startDate, end_date: endDate});
    
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/system-history/delete-by-date-filter`;
    const resp = await httpPostAsync(url, data);

    return resp.data;
}
import { httpGetAsync, httpUploadAsync} from "./requestService";

export const uploadAsync = async(file, fileName) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/files/upload`;
    const resp = await httpUploadAsync(url, file, fileName);

    return resp.data;
}

export const uploadImageAsync = async(file, fileName) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/images/upload`;
    const resp = await httpUploadAsync(url, file, fileName);

    return resp.data;
}

export const removeAsync = async(fileName) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/files/delete?${fileName}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}
import { createStore } from 'vuex';

const store = createStore({
    state () {
        return {
            // userRoles state use only for LeftMenu display and be set when user login
            userRoutes: [],
            systemRole: 'EMPLOYEE',
            displayName: ''
        }
    },
    mutations: {
        setUserAccessRoutes(state, userRoutes) {
            state.userRoutes = userRoutes;
        },
        setSystemRole(state, systemRole) {
            state.systemRole = systemRole;
        },
        setCurrentUserDisplayName(state, displayName) {
            state.displayName = displayName;
        },
        resetState(state){
            state.userRoutes = [];
            state.systemRole = 'EMPLOYEE';
            state.displayName = '';
        },
    },
    getters: {
        getFrontEndUserRoutes(state) {
            return state.userRoutes.filter(item => item.route_name.includes('frontend.'));
        },
        getSystemRole(state){
            return state.systemRole;
        },
        getCurrentUserLastName(state){
            const arr = state.displayName.split(' ');
            return arr[arr.length-1]; 
        }
    }
})

export default store;
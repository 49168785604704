<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Push an update</h1>
            </div>
            <div class="center-box">
                <div class="form-group">
                    <label for="projectName">Tên dự án <span class="cl-red">*</span></label>
                    <input v-model="projectName" type="text" class="form-control" id="projectName" disabled>
                </div>
                <div class="form-group">
                    <label for="version">Phiên bản <span class="cl-red">*</span></label>
                    <input v-model="version" type="text" class="form-control" id="version">
                </div>
                <div class="form-group">
                    <label for="updateUrl">Update URL <span class="cl-red">*</span></label>
                    <div style="display: flex;">
                        <input v-model="updateUrl" type="text" class="form-control" id="updateUrl">
                        <input id="file-upload" type="file" ref="file" @change="submitUploadAsync" style="display: none;">
                        <button type="button" class="btn btn-secondary btn-make-update" @click="chooseUploadFile">
                            Upload
                        </button>
                    </div>
                </div>
                
            </div>
            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo update</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import { uploadAsync } from '@/services/fileService';
import { findAsync, updateAsync } from '@/services/projectService';

export default {
    name: 'ProjectPushUpdatePage',
    data() {
        return {
            id: undefined,
            projectName: '',
            version: '',
            updateUrl: '',
            updateFileName: '',
            onSubmiting: false
        }
    },
    created() {
        document.title = 'Push an update';
    },
    async mounted() {
        this.id = this.$route.params['id'];
        const project = (await findAsync(this.id)).data;
        this.projectName = project.name;
        this.version = project.version;
        this.updateUrl = project.update_url;
    },
    methods: {
        async submitAsync() {
            this.onSubmiting = true;

            if (!this.updateUrl) {
                this.$toast.error('Vui lòng nhập update URL');
                this.onSubmiting = false;
                return;
            }

            if (!this.updateFileName)
                this.updateFileName = this.projectName + '_update_' + this.version;

            const projectObj = { version: this.version, update_url: this.updateUrl, update_file_name: this.updateFileName };
            const resp = await updateAsync(this.id, projectObj);

            if (resp.success) {
                this.$router.push({ name: 'projects' });
                this.$toast.success('Tạo update thành công');
            } else {
                this.$toast.error(resp.message + ': ' + resp.data);
            }

            this.onSubmiting = false;
        },
        // Upload file ----------------------------------------------------------
        chooseUploadFile() {
            this.$refs.file.click();
        },
        async submitUploadAsync() {
            const uploadBtn = document.querySelector('.btn-make-update');
            uploadBtn.disabled = true;
            uploadBtn.innerText = 'Uploading';

            const file = this.$refs.file.files[0];
            let fileName = this.projectName + '_update_' + this.version;
            fileName = fileName.toLowerCase();
            const resp = await uploadAsync(file, fileName);

            if (resp.success == true) {
                this.updateFileName = resp.data.file_name;
                this.updateUrl = '/storage/updates/' + resp.data.file_name;
            } else {
                this.$toast.error('Upload thất bại, vui lòng thử lại');
            }

            uploadBtn.disabled = false;
            uploadBtn.innerText = 'Upload';
        }
    }
}
</script>

<style scoped>
.btn-make-update {
    height: 32px;
    margin-left: 10px;
    min-width: 100px;
}

.error {
    color: red;
}
</style>
import { httpGetAsync, httpPostAsync } from "./requestService";

/**
 *  Get data list
 * @param {*} page 
 * @param {*} perPage 
 * @param {*} filter {from_project_name, from_customer_name, order_by}
 * @returns 
 */
export const getAsync = async (page, perPage, filter) => {
    let fromProjectName = filter.from_project_name;
    let fromCustomerName = filter.from_customer_name;
    let orderBy = filter.order_by;

    if (!fromCustomerName) fromCustomerName = '';
    if (!fromProjectName) fromProjectName = '';
    if (!orderBy) orderBy = 'duplicate_count';

    const url = `${process.env.VUE_APP_BACKEND_API_URL}/data-report?page=${page}&per_page=${perPage}&order_by=${orderBy}&from_project_name=${fromProjectName}&from_customer_name=${fromCustomerName}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/data-report/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const bulkRemoveAsync = async (ids) => {
    const obj = {ids: ids};
    const data = JSON.stringify(obj);
    
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/data-report/bulk-delete`;
    const resp = await httpPostAsync(url, data);

    return resp.data;
}
<!-- 
    User search box
    -------------------------------------------------------------------------------
    return selected id throw 'onchange' emnit, you can also use v-model

    <UserSearchBox @onchange='getValue'></UserSearchBox>

    methods: {
        getValue(id){

        }
    }
-->

<template>
    <div class="userSearchBox-container">
        <div class="userSearchBox-container__content" @click="isOpen = !isOpen">
            <span class="selected-content">{{selectedText}}</span>
            <span class="dropdown-btn"><i class="fas fa-caret-down"></i></span>
        </div>
        
        <div class="dropdown-container" v-show="isOpen">
            <ul>
                <li class="sticky-top search-container">
                    <input v-model="search" type="text" class="form-control" @input="handleSearchAsync" v-on:keydown.enter="enterEvent($event)">
                </li>
                <li v-if="suggestList.length === 0" class="no-data">Không có dữ liệu</li>
                <li v-for="item in suggestList" v-bind:key="item.id" @click="setSelectedId(item.id, item.display_name + ' - '+item.email)">
                    {{ item.display_name }} - {{ item.email }}
                </li>
                <li v-show="isLoadingSuggest">
                    <div class="text-center">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {getCustomersAsync} from '@/services/userService';

export default {
    name: 'UserSearchBox',
    data(){
        return {
            search: '',
            isOpen: false,
            suggestList: [],
            isLoadingSuggest: false,
            selectedText: '-- Chọn khách hàng',
            lastInputAt: Date.now()
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        setSelectedId(id, text){
            if (id === undefined) {
                this.selectedText = '-- Chọn khách hàng';
                return;
            }
            this.isOpen = false;
            this.selectedText = text;

            this.$emit('update:modelValue', id);
            this.$emit('onchange', id);
        },
        handleSearchAsync(){
            // If the user does not send any more requests after 1 second, handle it on the server
            setTimeout(async() => {
                const ms = 700;
                const now = Date.now();
                const diff = Math.abs(now - this.lastInputAt);
                if (diff < ms) {
                    this.lastInputAt = Date.now();
                    return;
                }

                // Search in server
                if (this.search.length == 0){
                    this.suggestList = [];
                    return;
                }
                this.isLoadingSuggest = true;
                const resp = await getCustomersAsync(1, 10, this.search, 'display_name', 'asc', '', 'id,email,display_name,login_token');

                if (resp.success == true){
                    this.suggestList = resp.data.data;
                }
                this.isLoadingSuggest = false;
                this.lastInputAt = Date.now();
            }, 1000);
        },
        enterEvent(e){
            if (this.suggestList.length > 0){
                this.setSelectedId(this.suggestList[0].id, this.suggestList[0].display_name + ' - ' + this.suggestList[0].email);
            }
            e.preventDefault();
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        }
    }
}
</script>

<style scoped>
.userSearchBox-container {
    border-radius: .25rem;
    border: 1px solid #ced4da;
    background: white;
    padding: 5px 5px 0px 10px;
    position: relative;
    height: 33.1px;
}

.userSearchBox-container__content {
    display: flex;
    cursor: pointer;
}

.selected-content {
    flex: 1;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}

.search-container {
    background: white !important;
}

.dropdown-btn i {
    color: gray !important;
    font-size: 18px;
    margin-top: 2px;
}

.dropdown-btn:hover i {
    opacity: 0.7;
}

.dropdown-container {
    position: absolute;
    top: 34px;
    left: 0px;
    width: 100%;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    max-height: 400px;
    min-height: 200px;
    overflow-y: auto;
    z-index: 1995;
}

.dropdown-container ul {
    list-style-type: none;
    padding: 0;
}

.dropdown-container ul li {
    display: block;
    padding: 10px;
    cursor: pointer;
}

.dropdown-container ul li:hover {
    background: #EEEEEE;
}

.no-data {
    color: #a7a7a7;
    text-align: center;
}

.no-data:hover {
    background: white !important;
}
</style>
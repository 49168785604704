import { httpGetAsync, httpPostAsync } from "./requestService";

export const getAsync = async (page, perPage, status, dateStart = '', dateEnd = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/affiliate?page=${page}&per_page=${perPage}&status=${status}&start_at=${dateStart}&end_at=${dateEnd}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/affiliate/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/affiliate/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const handleAsync = async (id, status, message) => {
    const postObj = {status: status, message: message};
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/affiliate/handle/${id}`;
    const resp = await httpPostAsync(url, JSON.stringify(postObj));

    return resp.data;
}
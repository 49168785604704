import { httpGetAsync, httpPostAsync} from "./requestService";

export const getAsync = async (page, perPage, searchLicense = '', type = '', customerId = '', projectId = '') => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses?page=${page}&per_page=${perPage}&license=${searchLicense}&type=${type}&customer_id=${customerId}&project_id=${projectId}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const findAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const createAsync = async (licenseObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/create`;
    const postData = JSON.stringify(licenseObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const bulkCreateAsync = async (obj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/bulk-create`;
    const postData = JSON.stringify(obj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const trialCreateAsync = async (obj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/trial-create`;
    const postData = JSON.stringify(obj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const updateAsync = async (id, licenseObj) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/edit/${id}`;
    const postData = JSON.stringify(licenseObj);
    const resp = await httpPostAsync(url, postData);

    return resp.data;
}

export const removeAsync = async (id) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/delete/${id}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const removeExpiredTrialLicensesAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/delete-expried-trial-licenses`;
    const resp = await httpGetAsync(url);

    return resp.data;
}

export const getRandomLicenseAsync = async () => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/get-random-license`;
    const resp = await httpGetAsync(url);

    if (resp.data.success == true)
        return resp.data.data;
    
    return 'UNKNOW_ERROR';
}

export const getActiveDevicesAsync = async (licenseId) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/get-active-devices/${licenseId}`;
    const resp = await httpGetAsync(url);

    if (resp.data.success == true)
        return resp.data.data;
    
    return [];
}

export const resetActiveDevicesAsync = async (licenseId) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/remove-all-active-devices/${licenseId}`;
    const resp = await httpGetAsync(url);

    if (resp.data.success == true)
        return true;
    
    return false;
}

export const convertDevicesFromOldSystemAsync = async (projectID, page) => {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/licenses/update-devices-from-old-system/${projectID}?page=${page}`;
    const resp = await httpGetAsync(url);

    return resp.data;
}
<template>
    <div>
        <div class="title-wrapper">
            <h1>Affiliate ({{ total }})</h1>
        </div>
        
        <!-- Filter -->
        <form>
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-2">
                        <select v-model="filter.status" class="form-control" @change="filterByStatusAsync">
                            <option value="PENDING">Chưa xử lý</option>
                            <option value="SUCCESS">Chấp nhận</option>
                            <option value="ERROR">Từ chối</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <input v-model="filter.startDate" type="date" class="form-control" @input="filterByDateAsync">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-2">
                        <input v-model="filter.endDate" type="date" class="form-control" @input="filterByDateAsync">
                        <div class="b5"></div>
                    </div>
                </div>
            </div>
        </form>

        <div class="total">
            Total: {{ formatVNPrice(totalPrice) }}
        </div>

        <!-- Data table -->
        <table class="data-table">
            <colgroup>
                <col width="25%">
                <col width="25%">
                <col width="25%">
            </colgroup>
            <thead>
                <tr>
                    <th>Tài khoản rút</th>
                    <th>Điểm rút</th>
                    <th>Trạng thái</th>
                    <th>Ngày tạo</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="loading-row" v-show="inLoading">
                    <td colspan="5">
                        <div class="loading-spinner"></div>
                    </td>  
                </tr>
                <tr class="no-data-row" v-if="data.length == 0 && !inLoading">
                    <td colspan="5">Không có dữ liệu để hiển thị</td>
                </tr>
                <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                    <td>
                        <b>{{ item.withdraw_user.display_name }}</b> &nbsp; <router-link :to="{name: 'licenses', query: {license: 'aff:'+item.withdraw_user.affiliate_code}}" title="View source"><i class="fas fa-external-link-alt"></i></router-link><br>
                        <small>{{ item.withdraw_user.email }}</small>
                    </td>
                    <td>{{ item.withdraw_point }} / Tổng số: {{ item.withdraw_user.affiliate_point }}<br>
                        <span v-if="item.withdraw_point > item.withdraw_user.affiliate_point && filter.status == 'PENDING'" class="badge bg-danger">Không đủ điểm rút</span>
                    </td>
                    <td>
                        <span :class="item.status">{{ item.status }}</span>

                        <small v-if="item.status=='SUCCESS'">
                            <br>
                            {{ formatDateTimeStr(item.paid_at) }}
                        </small>
                    </td>
                    <td>
                        {{ formatDateTimeStr(item.created_at) }}
                    </td>
                    <td>
                        <router-link :to="{name: 'affiliate.handle', params: {id: item.id}}" class="btn btn-secondary">
                            <span v-if="item.status=='PENDING'">Xử lý</span>
                            <span v-else>Chi tiết</span>
                        </router-link>
                        <a v-if="item.status=='PENDING'" @click="removeAsync(item.id)" class="btn-del" title="Xóa"><i class="fas fa-trash-alt"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>
    </div>
</template>
  
<script>
import * as db from '@/services/affiliateService';
import { getItemIndex } from '@/helpers/arrayHelper';
import { formatDateTimeString, formatPrice } from '@/helpers/strHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';

import NProgress from 'nprogress';

export default {
    name: 'AffiliateOrderPage',
    created() {
        document.title = 'Affiliate';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 15;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.status) this.filter.status = 'PENDING';
    },
    // Get data
    async mounted() {
        await this.loadAsync(false);
    },
    data() {
        return {
            data: [],
            filter: {
                page: 1,
                perPage: 15,
                status: 'PENDING',
                startDate: '',
                endDate: ''
            },
            totalPrice: 0,
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có chắc chắn muốn xóa dữ liệu đang chọn')) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.total--;
                    this.$toast.success('Xóa lệnh rút thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });

            const result = await db.getAsync(this.filter.page, this.filter.perPage, this.filter.status, this.filter.startDate, this.filter.endDate);

            if (result.success == true)
            {
                this.data = result.data.affiliate.data;
                this.total = result.data.affiliate.total;
                this.totalPage = result.data.affiliate.last_page;
                this.totalPrice = result.data.paid_point;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
            }

            this.inLoading = false;
        },
        

        // Filter ----------------------------------------------------------------------------------
        async filterByStatusAsync(){
            this.filter.page = 1;
            await this.loadAsync();
        },

        async filterByDateAsync(){
            this.filter.page = 1;
            await this.loadAsync();
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // Helpers -------------------------------------------------------------------------------------
        formatDateTimeStr(dateTimeString){
            return formatDateTimeString(dateTimeString);
        },
        formatVNPrice(price){
            return formatPrice(price * 1000);
        },
        getLastdayOfCurrentMonth(){
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const nextMonth = currentMonth + 1;
            const nextMonthFirstDay = new Date(currentDate.getFullYear(), nextMonth, 1);
            const lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1);
            const lastDay = lastDayOfMonth.getDate();

            return lastDay;
        }
    }
    
}
</script>
  
<style scoped>
.btn {
    margin-left: 10px;
}
.btn-del {
    margin-left: 10px;
}

.PENDING {
    color: black;
}

.SUCCESS {
    color: #008000;
}

.ERROR {
    color: red;
}

.total {
    margin-bottom: 20px;
    padding: 5px 15px;
    border: 1px solid #0080c0;
    color: #0080c0;
    background: #dcf3ff;
    border-radius: 5px;
    display: block;
}
</style>
<template>
    <div>
        <div class="title-wrapper">
            <h1>Licenses ({{ total }})</h1>
            <div>
                <router-link class="btn btn-light" :to="{name: 'licenses.trial-create'}">Thêm license dùng thử</router-link>
                <router-link class="btn btn-light" :to="{name: 'licenses.bulk-create'}">Thêm nhiều license</router-link>
                <router-link class="btn btn-success" :to="{name: 'licenses.create'}"><i class="fas fa-plus"></i> Thêm license</router-link>
            </div>
        </div>
        
        <!-- Filter -->
        <form>
            <div class="filter-form">
                <div class="row">
                    <div class="col-md-3">
                        <input v-model="filter.license" v-on:keyup.enter="searchAsync" name="license" type="text" placeholder="Tìm kiếm license..." class="form-control">
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <UserSearchBox ref="selectCustomer" @onchange="updateCustomerIdFilterAsync"></UserSearchBox>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <SelectBox ref="selectProject" placeHolder="-- Chọn dự án" @onchange="updateProjectIdFilterAsync"></SelectBox>
                        <div class="b5"></div>
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-light btn-reset-filter" @click="resetFilterAsync">Đặt lại bộ lọc</button>
                        <div class="b5"></div>
                    </div>
                </div>
                <div class="filter-type-container">
                    <span class="license-type-tab" :class="{active: filter.type == 'ALL'}" @click="filterByTypeAsync('ALL')">Tất cả</span>
                    <span class="license-type-tab" :class="{active: filter.type == 'LIFETIME'}" @click="filterByTypeAsync('LIFETIME')">Vĩnh viễn</span>
                    <span class="license-type-tab" :class="{active: filter.type == 'MONTHLY'}" @click="filterByTypeAsync('MONTHLY')">Thuê tháng</span>
                    <span class="license-type-tab" :class="{active: filter.type == 'TRIAL'}" @click="filterByTypeAsync('TRIAL')">Dùng thử</span>
                </div>
                
            </div>
        </form>

        <!-- Data table -->
        <div class="data-table">
            <table class="data-table--vertical-top">
                <colgroup>
                    <col width="25%">
                    <col width="25%">
                </colgroup>
                <thead>
                    <tr>
                        <th>License</th>
                        <th>Khách hàng</th>
                        <th>Thiết bị</th>
                        <th>Ngày mua</th>
                        <th>Loại</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="loading-row" v-show="inLoading">
                        <td colspan="6">
                            <div class="loading-spinner"></div>
                        </td>  
                    </tr>
                    <tr class="no-data-row" v-if="data.length == 0 && !inLoading">
                        <td colspan="6">Không có dữ liệu để hiển thị</td>
                    </tr>
                    <tr v-for="item in data" v-bind:key="item.id" v-show="!inLoading">
                        <td :title="'Tạo bởi: '+ item.created_user.display_name">
                            <b>{{ item.license }}</b><br>
                            <small class="addination-info">{{ item.project.name }}</small>
                        </td>
                        <td>
                            <span class="filter-button r15" @click="filterByCustomerIdAsync(item.owner_id, item.customer.display_name)">
                                {{ item.customer.display_name }}
                            </span>
                            <router-link :to="{name: 'users.edit', params: {id: item.owner_id}}" class="r10" title="Sửa người dùng">
                                <i class="fas fa-user-edit"></i>
                            </router-link>
                            <router-link :to="{name: 'users', query: {search: item.customer.email}}" class="r10" title="Thông tin khách hàng">
                                <i class="fas fa-info-circle"></i>
                            </router-link>
                            <br>
                            <small class="addination-info">{{item.customer.email}}</small>
                        </td>
                        <td>
                            <span>
                                <b style="color: red">{{ item.active_devices_count }}</b> / <b>{{ item.limit_devices }}</b>&nbsp;
                                <span title="Hiển thị danh sách thiết bị đã kích hoạt" class="device-option" @click="showActiveDeviceModalAsync(item)"><i class="fas fa-info-circle" style="color: #0080C0 !important"></i></span>
                                <span title="Đặt lại danh sách thiết bị" class="device-option" @click="resetDevicesAsync(item)"><i class="fas fa-sync-alt" style="color: #0080C0 !important"></i></span>
                            </span>
                        </td>
                        <td>
                            <span>{{ formatDateStr(item.created_at) }}</span>
                        </td>
                        <td>
                            <span v-if="item.type === 'LIFETIME'" class="badge bg-success">Vĩnh viễn</span>
                            <span v-else-if="item.type === 'TRIAL'" class="badge bg-danger">Dùng thử</span>
                            <span v-else-if="item.type === 'MONTHLY'" class="badge bg-warning">Thuê tháng</span>
                        </td>
                        <td class="table-option">
                            <router-link :to="{name: 'licenses.edit', params: {id: item.id}}" class="btn-edit" title="Sửa"><i
                                    class="far fa-edit"></i></router-link>
                            <a @click="removeAsync(item.id)" class="btn-del" title="Xóa"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        

        <nav>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" @click="prevPageAsync()"><i class="fas fa-angle-left"></i></a></li>
                <li class="page-item" :class="{'active': (page == this.filter.page)}" v-for="page in paginationPageList" v-bind:key="page">
                    <a class="page-link" @click="goToPageAsync(page)">{{page}}</a>
                </li>
                <li class="page-item"><a class="page-link" @click="nextPageAsync()"><i class="fas fa-angle-right"></i></a></li>
            </ul>
        </nav>

        <!-- Modal device list -->
        <ActiveDeviceModal ref="activeDeviceModal" @close="closeActiveDeviceModal" v-show="isShowActiveDevicesModal"></ActiveDeviceModal>
    </div>
</template>
  
<script>
import * as db from '@/services/licenseService';
import {getAsync as getProjectsAsync} from '@/services/projectService';
import { getItemIndex } from '@/helpers/arrayHelper';
import { formatDateString } from '@/helpers/strHelper';
import { calcPaginationPageList } from '@/helpers/paginationHelper';

import NProgress from 'nprogress';
import UserSearchBox from '@/components/UserSearchBox.vue';
import SelectBox from '@/components/SelectBox.vue';
import ActiveDeviceModal from '@/pages/Admin/Licenses/actice_devices.vue';

export default {
    name: 'LicensePage',
    components: {
        UserSearchBox,
        SelectBox,
        ActiveDeviceModal
    },
    created() {
        document.title = 'Quản lý License';
        this.filter = this.$route.query;

        // Default
        if (!this.filter.perPage) this.filter.perPage = 15;
        if (!this.filter.page) this.filter.page = 1;
        if (!this.filter.type) this.filter.type = 'ALL';
    },
    // Get data
    async mounted() {
        await this.loadProjectSelectBoxAsync();
        await this.loadAsync(false);
        document.addEventListener('click', this.handleClickOutsideModal);
    },
    data() {
        return {
            data: [],
            isShowActiveDevicesModal: false,
            filter: {
                page: 1,
                perPage: 15,
                license: '',
                customer_id: '',
                project_id: '',
                type: 'ALL'
            },
            total: 0,
            totalPage: 0,
            paginationPageList: [],
            inLoading: true
        }
    },
    methods: {
        async removeAsync(id) {
            const item = getItemIndex(this.data, 'id', id);

            if (confirm('Bạn có muốn xóa vĩnh viễn license: ' + item.item.license)) {
                NProgress.start();
                const result = await db.removeAsync(id);
                if (result.success == true) {
                    this.data.splice(item.index, 1);
                    this.total--;
                    this.$toast.success('Xóa license thành công');
                } else {
                    this.$toast.error(result.message);
                }
                NProgress.done();
            }
        },

        async searchAsync(){
            this.filter.page = 1;
            this.filter.project_id = undefined;
            this.filter.customer_id = undefined;

            await this.loadAsync();
        },

        async loadAsync(scrollToTop = true) {
            this.inLoading = true;
            if (scrollToTop)
                window.scrollTo({ top: 0, behavior: 'smooth' });

            let type = this.filter.type;
            if (type == 'ALL') type = '';

            const result = await db.getAsync(this.filter.page, this.filter.perPage, this.filter.license, type, this.filter.customer_id, this.filter.project_id);

            if (result.success == true)
            {
                this.data = result.data.data;
                this.total = result.data.total;
                this.totalPage = result.data.last_page;
                this.paginationPageList = calcPaginationPageList(this.filter.page, this.totalPage);
                this.inLoading = false;
            }
        },

        async loadProjectSelectBoxAsync(){
            const result = await getProjectsAsync(1, 100, '', 'name', 'asc', 'id,name');
            if (result.success == true)
                this.$refs.selectProject.updateSuggestList(result.data.data, 'name', 'id');

            if (this.filter.project_id){
                this.$refs.selectProject.setSelectedId(this.filter.project_id);
            }
        },

        async resetDevicesAsync(rowItem){
            const result = await db.resetActiveDevicesAsync(rowItem.id);
            if (result){
                this.$toast.success('Đặt lại thiết bị thành công');
                rowItem.active_devices_count = 0;
            }
        },
        
        // Filter ----------------------------------------------------------------------------------
        async resetFilterAsync(){
            this.filter.license = '';
            this.filter.type = 'ALL';
            this.filter.customer_id = undefined;
            this.filter.project_id = undefined;
            this.$refs.selectProject.setSelectedId(this.filter.project_id);
            this.$refs.selectCustomer.setSelectedId(this.filter.customer_id, '');
            this.filter.page = 1;
  
            await this.loadAsync();
        },

        async updateCustomerIdFilterAsync(id){
            this.filter.customer_id = id;
            this.filter.page = 1;

            await this.loadAsync();
        },

        async updateProjectIdFilterAsync(id){
            this.filter.project_id = id;
            this.filter.page = 1;

            await this.loadAsync();
        },

        async filterByProjectIdAsync(id){
            this.filter.project_id = id;
            this.filter.page = 1;
            this.$refs.selectProject.setSelectedId(this.filter.project_id);

            await this.loadAsync();
        },

        async filterByCustomerIdAsync(id, ownerName){
            this.filter.customer_id = id;
            this.filter.page = 1;
            this.$refs.selectCustomer.setSelectedId(this.filter.customer_id, ownerName);

            await this.loadAsync();
        },

        async filterByTypeAsync(type){
            this.filter.page = 1;
            this.filter.type = type;
            await this.loadAsync();
        },

        // Pagination --------------------------------------------------------------------------------
        async nextPageAsync(){
            if (this.filter.page == this.totalPage)
                return;

            this.filter.page++;
            await this.loadAsync();
        },

        async prevPageAsync(){
            if (this.filter.page <= 1)
                return;

            this.filter.page--;
            await this.loadAsync();
        },

        async goToPageAsync(page){
            this.filter.page = page;
            await this.loadAsync();
        },

        // UI event ------------------------------------------------------------------------------------
        async showActiveDeviceModalAsync(rowItem) {
            this.isShowActiveDevicesModal = true;
            this.$refs.activeDeviceModal.loadDataAsync(rowItem);
        },

        closeActiveDeviceModal(){
            this.isShowActiveDevicesModal = false;
            this.$refs.activeDeviceModal.clearData();
        },

        handleClickOutsideModal(event) {
            if (!this.$refs.activeDeviceModal)
                return;

            const modalEl = this.$refs.activeDeviceModal.$el;
            if (this.isShowActiveDevicesModal && this.$refs.activeDeviceModal.dataLoaded && !modalEl.contains(event.target)) {
                this.closeActiveDeviceModal();
            }
        },

        // Helpers -------------------------------------------------------------------------------------
        formatDateStr(dateString){
            return formatDateString(dateString);
        }
    }
    
}
</script>
  
<style scoped>
.btn {
    margin-left: 10px;
}

.device-option {
    cursor: pointer;
    margin: 0px 0px 0px 5px;
}

.device-option i {
    margin-right: 5px;
}

b {
    color: #474747;
}

.filter-type-container {
    padding: 10px 0;
}

.filter-type-container span {
    margin-right: 15px;
    padding: 5px 0px;
    cursor: pointer;
    font-size: 13px;
}

.filter-type-container .active {
    border-bottom: 2px solid #0080ff;
}


.filter-type-container span:hover {
    font-weight: bold;
}

.addination-info {
    color: #717171;
}
</style>
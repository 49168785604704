<template>
    <div>
        <form @submit.prevent="submitAsync">
            <div class="center-box--without-bg">
                <h1>Thêm dự án</h1>
            </div>

            <div class="center-box">
                <h5>1. Thông tin chung</h5>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="projectName">Tên dự án <span class="cl-red">*</span></label>
                            <input v-model="projectObj.name" type="text" class="form-control" id="projectName">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="version">Phiên bản <span class="cl-red">*</span></label>
                            <input v-model="projectObj.version" type="text" class="form-control" id="version">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="limitDevices">Giới hạn thiết bị mặc định <span class="cl-red">*</span></label>
                            <input v-model="projectObj.default_limit_devices" type="number" class="form-control"
                                id="limitDevices">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="downloadUrl">Download url</label>
                            <input v-model="projectObj.download_url" type="text" class="form-control" id="downloadUrl">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="message">Thông báo</label>
                            <input v-model="projectObj.message" type="text" class="form-control" id="message">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="metaData">Meta data</label>
                            <div style="display: flex">
                                <input v-model="projectObj.meta_data" type="text" class="form-control" id="metaData">
                                <button type="button" class="btn btn-success edit-btn"
                                @click="modalStatus.metaDataVisiable = !modalStatus.metaDataVisiable" title="Sửa"><i
                                    class="fas fa-pencil-alt"></i></button>
                            </div>
                        </div>
                        
                        <div class="editor-modal" v-show="modalStatus.metaDataVisiable">
                            <textarea v-model="projectObj.meta_data" class="form-control"></textarea>
                            <div class="saving-wrapper">
                                <button type="button" class="btn btn-success"
                                    @click="modalStatus.metaDataVisiable = !modalStatus.metaDataVisiable">Cập nhật</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style="display: flex;">
                    <input v-model="projectObj.is_active" type="checkbox" id="active" style="margin-right: 6px">
                    <label for="active">Kích hoạt</label>
                </div>

            </div>
            <div class="center-box">
                <h5>2. Modules</h5>
                <hr>
                <span class="add-slot-btn" @click="addNewModuleSlot">Thêm module</span>
                <br />
                <div class="module-wrapper">
                    <div v-for="item in modules" v-bind:key="item.id">
                        <div class="row">
                            <div class="col-md-6">
                                <input placeholder="Module" v-model="item.name" type="text" class="form-control">
                            </div>
                            <div class="col-md-6">
                                <div style="display: flex;">
                                    <input placeholder="Giá (x1000 vnd)" v-model="item.price" type="text"
                                        class="form-control">
                                    <a @click="removeModuleSlot(item.id)" class="btn btn-danger edit-btn"><i
                                            class="fas fa-trash-alt"></i></a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-box">
                <h5>3. Gói dự án</h5>
                <hr>
                <span class="add-slot-btn" @click="addNewPackageSlot">Thêm gói</span>
                <br /> <br />
                <div class="package-wrapper">
                    <div v-for="item in packages" v-bind:key="item.id">
                        <div class="row">
                            <div class="col-md-3">
                                <input placeholder="Tên gói" v-model="item.name" type="text" class="form-control">
                            </div>
                            <div class="col-md-3">
                                <input placeholder="Giá (x1000 vnd)" v-model="item.price" type="number" class="form-control">
                            </div>
                            <div class="col-md-3">
                                <input placeholder="Số ngày hết hạn" v-model="item.expires_days" type="number" class="form-control">
                            </div>
                            <div class="col-md-3">
                                <div style="display: flex;">
                                    <input placeholder="Số thiết bị" v-model="item.limited_devices" type="number" class="form-control">
                                    <a @click="removePackageSlot(item.id)" class="btn btn-danger edit-btn"><i
                                            class="fas fa-trash-alt"></i></a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="center-box--without-bg saving-wrapper">
                <button type="submit" class="btn btn-success" :class="{ 'loading-btn': onSubmiting }">
                    <div class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span>Tạo dự án</span>
                </button>
                <a @click="this.$router.go(-1)" class="btn btn-danger">Quay lại</a>
            </div>
        </form>
    </div>
</template>

<script>
import * as db from '@/services/projectService';

export default {
    name: 'ProjectCreatePage',
    created() {
        document.title = 'Tạo project';
    },
    data() {
        return {
            projectObj: {
                is_active: true,
                update_url: '',
                update_file_name: '',
                meta_data: '',
                message: '',
                download_url: '',
                is_required_update: false
            },
            modules: [],
            packages: [],
            modalStatus: {
                metaDataVisiable: false
            },
            onSubmiting: false
        }
    },
    methods: {
        async submitAsync() {
            this.onSubmiting = true;

            // Standardize data
            let rawModules = [];
            this.modules.forEach(item => {
                rawModules.push({ name: item.name, price: item.price });
            });
            this.projectObj.modules = rawModules;

            let rawPackages = [];
            this.packages.forEach(item => {
                rawPackages.push({ name: item.name, price: item.price, expires_days: item.expires_days, limited_devices: item.limited_devices });
            });
            this.projectObj.packages = rawPackages;


            if (!this.projectObj.meta_data)
                this.projectObj.meta_data = '{}';

            const result = await db.createAsync(this.projectObj);

            if (result.success == true) {
                this.$toast.success('Thêm dự án thành công');
                this.$router.push({name: 'projects', query: {search: this.projectObj.name}});
            } else {
                this.$toast.error(result.message);
            }

            this.onSubmiting = false;
        },

        // Modules
        addNewModuleSlot() {
            const module = { id: this.generateGUID(), name: '', price: null };
            this.modules.push(module);
        },
        removeModuleSlot(id) {
            let index = 0;
            for (let i = 0; i < this.modules.length; i++) {
                if (this.modules[i].id == id) {
                    index = i;
                    break;
                }
            }
            this.modules.splice(index, 1);
        },

        // Packages
        addNewPackageSlot() {
            const packageObj = { id: this.generateGUID(), name: '', price: null, expires_days: 10000, limited_devices: null};
            this.packages.push(packageObj);
        },
        removePackageSlot(id) {
            let index = 0;
            for (let i = 0; i < this.packages.length; i++) {
                if (this.packages[i].id == id) {
                    index = i;
                    break;
                }
            }
            this.packages.splice(index, 1);
        },

        // Helper -------------------------------------------------------------------
        generateGUID() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        }
    }
}
</script>

<style scoped>
.edit-btn {
    height: 32px;
    margin-left: 10px;
    padding-left: 15px !important;
}

.add-slot-btn {
    color: #00A88E;
    cursor: pointer;
}

.post-thumb-wrapper {
    width: 90%;
    height: 200px;
    border: 1px solid #00A88E;
    border-radius: 5px;
    margin-top: 30px;
    cursor: pointer;
    position: relative;
}

.post-thumb-wrapper span {
    top: 80px;
    width: 100%;
    position: absolute;
    display: block;
    text-align: center;
}

.post-thumb-wrapper img {
    width: 100%;
    height: 100%;
    display: block;
}

.editor-modal {
    border: 2px solid #00A88E;
    border-radius: 5px;
    padding: 20px;
    background: white;
    margin-top: 15px;
}

.editor-modal textarea {
    min-height: 200px;
    margin-bottom: 15px;
}

.error {
    color: red;
}

.module-wrapper {
    margin-top: 10px;
}
</style>